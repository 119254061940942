import { IFormData } from '../../../model/IFormData';
import { IFormPolicyHolder } from '../../../model/IFormPolicyHolder';
import { IVnOutput } from './IVnOutput';
import { IAdresse } from '../IAdresse';
import Gender from '../../../model/Gender';
import { IFormInsuredPerson } from '../../../model/IFormInsuredPerson';
import { IVpOutput } from './IVpOutput';
import { mapDateInputValueToDate } from '../../../common/dateMapper';
import { IPersonGatewayInfo } from '../../../model/IPersonGatewayInfo';
import { IAppState } from '../../../state/AppState'
import { IAppProps } from '../../../App'
import { IStaticPersonalData } from '../../../model/IStaticPersonalData'
import { sanitizeValues } from '../../../sanitize'
import { getValidationResults } from '../../../state/IValidationResults'
import { getKeweConsentResult, mapKeweConsent } from '../../../utils/keweConsentResult';

const mapToOutputAdresse = (vp: IFormInsuredPerson, vnAddress: IAdresse, addressId: string | undefined): IAdresse => {
    const source: IAdresse = !vp.hasDifferentAddress
        ? vnAddress
        : vp;

    const {strasse, hausnummer, plz, ort} = source;
    return {
        strasse,
        hausnummer,
        plz,
        ort,
        id: addressId
    };
};

// tslint:disable-next-line: no-any
const  parseValueAsBoolean = (value: any): boolean | undefined => {
    if (value !== undefined) {
        if (typeof value === 'string') {
            return (value as string).toLowerCase() === 'true';
        } else if (typeof value === 'boolean') {
            return value;
        }
    }
    return undefined;
};

const mapToVnOutput = (vn: IFormPolicyHolder, disableEwe: boolean, personsGatewayInfo: IPersonGatewayInfo[], staticPersonData: IStaticPersonalData): IVnOutput => {
    const result: IVnOutput = {
        titel: vn.titel,
        vorname: vn.vorname,
        nachname: vn.nachname,
        adresse: {
            id: personsGatewayInfo[0].addressId,
            strasse: vn.strasse,
            hausnummer: vn.hausnummer,
            plz: vn.plz,
            ort: vn.ort
        },
        geburtsort: vn.geburtsort,
        staatsangehoerigkeit: vn.staatsangehoerigkeit || '',
        geschlecht: vn.geschlecht as Gender,
        einverstaendnisEmailWerbung: vn.emailEwe.length > 0,
        einverstaendnisTelefonWerbung: !disableEwe,
        vorwahl: vn.vorwahl,
        rufnummer: vn.rufnummer,
        email: vn.emailEwe || vn.email || undefined,
        iban: vn.iban,
        bic: vn.iban.startsWith('DE') ? undefined : vn.bic,
        id: personsGatewayInfo[0].personId,
        role: personsGatewayInfo[0].role,
        wirtschaftlicheigentverantwortlich: vn.gwgoptions ? parseValueAsBoolean(vn.gwgoptions.wirtschaftlicheigentverantwortlich) : undefined,
        ...(staticPersonData.enableNewPersonalDataFlow && { keweConsent: mapKeweConsent(getKeweConsentResult(staticPersonData.enableNewPersonalDataFlow,
            staticPersonData.enableKEweConsent, {vn, vps: []} ))})
    };

    if (Array.isArray(vn.abbuchungsTage)) {
        result.tagDerAbbuchung = vn.tagDerAbbuchung;
    }

    if (vn.fuehrerschein) {
        result.fuehrerschein = {
            datum: mapDateInputValueToDate(vn.fuehrerschein.datum)
        };
    }

    return result;
};

const mapToVpsOutput = (vps: IFormInsuredPerson[], vnAddress: IAdresse, personsGatewayInfo: IPersonGatewayInfo[]): IVpOutput[] => {
    return vps.map((vp: IFormInsuredPerson, index): IVpOutput => {
        return {
            geschlecht: vp.geschlecht as Gender,
            titel: vp.titel,
            vorname: vp.vorname,
            nachname: vp.nachname,
            geburtsort: vp.geburtsort,
            staatsangehoerigkeit: vp.staatsangehoerigkeit || '',
            adresse: mapToOutputAdresse(vp, vnAddress, personsGatewayInfo[index + 1].addressId),
            geburtsdatum: mapDateInputValueToDate(vp.geburtsdatum),
            id: personsGatewayInfo[index + 1].personId,
            role: personsGatewayInfo[index + 1].role,
            wirtschaftlicheigentverantwortlich: vp.gwgoptions ? parseValueAsBoolean(vp.gwgoptions.wirtschaftlicheigentverantwortlich) : undefined
        };
    });
};

export const mapPersonDataChangeOutput = (
  staticPersonData: IStaticPersonalData,
  formData: IFormData,
  appState: IAppState,
  appProps: IAppProps,
) => {
    const sanitizedValues: IFormData = sanitizeValues(formData);

    const validationResults = getValidationResults(
      formData,
      staticPersonData,
      appState.isIbanValid,
      appProps.input.vn.isIbanAlwaysVisible || false);
    return {
        vn: mapToVnOutput(sanitizedValues.vn, Boolean(appProps.input.disableEwe), appState.personsGatewayInfo, staticPersonData),
        vps: mapToVpsOutput(sanitizedValues.vps, sanitizedValues.vn, appState.personsGatewayInfo),
        isAngebotMoeglich: validationResults.isAngebotMoeglich,
        isAbschlussMoeglich: validationResults.isAbschlussMoeglich,
        isPromotionSuccessful: validationResults.isPromotionSuccessful
    }
}
