import { IGatewayPerson, IGatewayPolicyHolder } from '../../../../infrastructure/model/IGatewayPerson'

export interface PersonReference {
  id: string;
  addressId: string;
  emailId?: string;
  phoneId?: string;
  paymentId?: string;
}

export const mapFromGateway = (item: IGatewayPerson): PersonReference  => {
  const person = item as IGatewayPolicyHolder
  return {
    id: person.personId,
    addressId: person.address?.id || '',
    emailId: person.mailAddress?.id,
    phoneId: person.phoneNumber?.id,
    paymentId: person.payment?.id
  }
}
