import * as React from 'react';
import { IMeta } from './IMeta';
import { DateInputRowWrapper } from './FormElementsWrapper';
import { Field } from 'redux-form';
import { DateValidation } from '../model/DateValidation';
import { DateInputValue } from '@eg/elements/components/DateInput/DateInput';
import { validateDatum } from '../validate';
import { mapDateInputValueToDate } from '../common/dateMapper';

interface IGeburtstagRowProps {
    isWriteable: boolean;
    validation?: DateValidation;
}

type IAllProps = IGeburtstagRowProps & IMeta;

class GeburtstagRow extends React.Component<IAllProps> {
    private readonly validate: (datum: DateInputValue) => string | undefined;

    constructor(props: IAllProps) {
        super(props);
        this.validate = (datum: DateInputValue) => {
            const errorMessage = validateDatum(datum);
            if (errorMessage !== undefined) {
                return errorMessage;
            }

            return props.validation
                ? props.validation(mapDateInputValueToDate(datum) as Date)
                : undefined;
        };
    }

    render() {
        return (
            <Field
                name={`${this.props.meta.scope}geburtsdatum`}
                component={DateInputRowWrapper}
                label="Geburtsdatum"
                disabled={!this.props.isWriteable}
                validate={this.validate}
                data-component-id={`${this.props.meta.role}geburtsdatum`}
            />
        );
    }
}

export default GeburtstagRow;
