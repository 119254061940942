import { CSSProperties } from "react"

export const basicAddressErrorMsg: CSSProperties = {
  textAlign: "left",
  margin: "auto",
  color: "#BF1528",
}

export const updatedAddressErrorMsg: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  padding: "12px 0px",
  gap: "24px",

  background: "rgba(232, 12, 38, 0.1)",
  borderRadius: "8px",

  flex: "none",
  alignSelf: "stretch",
}

export const addressCorrectedMsg: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "12px 0px",
  gap: "24px",

  background: "#E2F6FA",
  borderRadius: "8px",

  flex: "none",
  order: "8",
  alignSelf: "stretch",
}
