export interface IMessaging {
    listenToRenderPersonendaten: (eventProcessor: (event: CustomEvent) => void) => void;
    listenToNextClicked: (eventProcessor: (event: CustomEvent) => void) => void;
    listenToUnmountPersonendaten: (eventProcessor: () => void) => void;
}

export class Messaging implements IMessaging {
    private handleNextClick: ((event: CustomEvent) => void) | undefined;

    listenToRenderPersonendaten = (eventProcessor: (event: CustomEvent) => void) => {
        // next line as EventListener: see https://github.com/Microsoft/TypeScript/issues/28357
        document.addEventListener('renderPersonendaten', eventProcessor as EventListener);
    }
    listenToNextClicked = (eventProcessor: (event: CustomEvent) => void) => {
        this.handleNextClick = eventProcessor;
        document.addEventListener('nextClicked', eventProcessor as EventListener);
    }
    listenToUnmountPersonendaten = (eventProcessor: (event: CustomEvent) => void) => {
        const processor = (e: CustomEvent) => {
            document.removeEventListener('nextClicked', this.handleNextClick as EventListener);
            eventProcessor(e);
        }
        document.addEventListener('unmountPersonendaten', processor as EventListener);
    }
}
