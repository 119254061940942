import { IAppState} from './AppState';
import { IPersonGatewayInfo } from '../model/IPersonGatewayInfo';
import { IGatewayPerson, IGatewayPolicyHolder } from '../infrastructure/model/IGatewayPerson'
const cloneDeep = require('lodash.clonedeep');

const personsGatewayInfoChanged = (state: IAppState, updated: IGatewayPerson[]): IAppState => {
    if(!updated || updated.length === 0) return state;
    if(state.personsGatewayInfo.length !== updated.length) {
      //Assign the newly created person ID to the person without any person ID
      const newState: IAppState = cloneDeep(state);
      const personsGatewayInfo: IPersonGatewayInfo[] = newState.personsGatewayInfo
      .map((item, index) => {
          if(item.personId) {
            index++;
            return item 
          }
          item.personId = updated[0].personId;
          index++;
          return item;
      });
      newState.personsGatewayInfo = personsGatewayInfo;
      return newState;
    }
    const newState: IAppState = cloneDeep(state);

    const personsGatewayInfo: IPersonGatewayInfo[] = newState.personsGatewayInfo
        .map((item, index) => {
            item.personId = updated[index].personId;
            item.addressId = updated[index].address?.id;
            if(index === 0) {
              const policyHolder = updated[index] as IGatewayPolicyHolder;
              item.emailId = policyHolder.mailAddress?.id;
              item.phoneId = policyHolder.phoneNumber?.id;
              item.paymentId = policyHolder.payment?.id;
            }
            index++;
            return item;
        });

    newState.personsGatewayInfo = personsGatewayInfo;
    return newState;
};

export { personsGatewayInfoChanged };
