import * as React from 'react';
import EmailEweField from '../EmailEweField';
import { TEXTS } from './KEweConsentSection.const';
import EmailField from '../EmailField';
import { IKeweConsentValidation } from '../../model/IKeweConsentValidation';
import TelefonField from '../TelefonField';

const KEweConsentSection: React.FC<
    IKeweConsentValidation & { hasVps: boolean }
> = ({ hasVps, hideKeweContactSection, hideKeweEmailField }) => (
    <>
        {!hideKeweContactSection && (
            <>
                <hr />

                <h3
                    style={{
                        margin: '1em 0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                    }}
                >
                    {TEXTS.TITLE}
                </h3>

                <div>
                    {!hideKeweEmailField && (
                        <>
                            <p>{TEXTS.EMAIL_DESCRIPTION}</p>
                            <EmailEweField />
                        </>
                    )}
                    <p>{TEXTS.PHONE_NUMBER_DESCRIPTION}</p>
                    <TelefonField label="Telefon / Mobilfunknummer" />
                </div>
            </>
        )}

        {hideKeweEmailField && <EmailField />}

        {hasVps && <hr />}
    </>
);

export default KEweConsentSection;
