import * as React from 'react';
import { DateValidation } from '../model/DateValidation';
import { INationality } from '../model/INationality';
import AdressSection from '../sections/AddressSection';
import PersonSection from '../sections/PersonSection';
import CheckboxField from './CheckboxField';
import Headline from './Headline';
import { IMeta } from './IMeta';

export interface IVpProps {
    headline: string;
    hasDifferentAddress: boolean;
    isWohnortReadOnly: boolean;
    isGeburtsdatumWriteable: boolean;
    geburtsdatumValidation?: DateValidation;
    cleanAddress: () => void;
    isGWGRequired?: boolean;
    showStaatsangehoerigkeit?: boolean;
    showGeburtsort?: boolean;
    touch: (...field: string[]) => void;
    nationalities: INationality[];
    showTitel: boolean;
    isSexReadOnly: boolean;
    role: string;
    isValidStreetVp?: boolean;
    setValidStreetVp?: (valid: boolean) => void;
    isValidHousenumberVp?: boolean;
    setValidHousenumberVp?: (valid: boolean) => void;
    isValidPostalCityVp?: boolean;
    setValidPostalCityVp?: (valid: boolean) => void;
    validateAddress?: boolean;
    isNewPersonalDataFlow?: boolean;
    vpAddressCorrected?: boolean;

}

type IAllProps = IVpProps & IMeta;

class Vp extends React.Component<IAllProps> {
    render() {
        return (
            <div key={this.props.meta.scope}>
                <Headline text={this.props.headline}/>

                {/* surprisingly redux-form does not support <FormSection> inside <FieldArray>
                therefore we explicitely have to pass the scope (vps[0], vps[1] etc.) to the Field components.
                see https://stackoverflow.com/questions/48695022/redux-form-fieldarray-formsection-for-complex-arrays-of-objects
                */}

                <PersonSection
                    meta={this.props.meta}
                    isGeburtsdatumWriteable={this.props.isGeburtsdatumWriteable}
                    geburtsdatumValidation={this.props.geburtsdatumValidation}
                    isSexReadOnly={this.props.isSexReadOnly}
                    isGWGRequired={this.props.isGWGRequired}
                    showStaatsangehoerigkeit={this.props.showStaatsangehoerigkeit}
                    showGeburtsort={this.props.showGeburtsort}
                    touch={this.props.touch}
                    nationalities={this.props.nationalities}
                    showTitel={this.props.showTitel}
                />

                <CheckboxField
                    meta={this.props.meta}
                    cleanAddress={this.props.cleanAddress}
                />

                {this.props.hasDifferentAddress &&
                <AdressSection
                    meta={this.props.meta}
                    isWohnortReadOnly={this.props.isWohnortReadOnly}
                    touch={this.props.touch}
                    isValidStreetVp={this.props.isValidStreetVp}
                    isValidHousenumberVp={this.props.isValidHousenumberVp}
                    isValidPostalCityVp={this.props.isValidPostalCityVp}
                    role = {'vp'}
                    validateAddress = {this.props.validateAddress}
                    isNewPersonalDataFlow={this.props.isNewPersonalDataFlow}
                    isVpAddressCorrected={this.props.vpAddressCorrected}
                />
            }
            </div>
        );
    }
}

export default Vp;
