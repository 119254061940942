import { IFormData } from '../model/IFormData';
import { IFormPolicyHolder } from '../model/IFormPolicyHolder';
import { IGatewayPerson, IGatewayPolicyHolder } from './model/IGatewayPerson';
import { IGatewayAddress } from './model/IGatewayAddress';
import Gender from '../model/Gender';
import { IGatewayMail } from './model/IGatewayMail';
import { IGatewayTelephoneNumber } from './model/IGatewayTelephoneNumber';
import { GatewayMailType } from './model/GatewayMailType';
import { GatewayLegalEntity } from './model/GatewayLegalEntity';
import { IGatewaySavePersons } from './model/IGatewaySavePersons';
import { IFormInsuredPerson } from '../model/IFormInsuredPerson';
import { mapDateInputValueToString } from '../common/dateMapper';
import { IPersonGatewayInfo } from '../model/IPersonGatewayInfo';
import { IGatewayPayment } from './model/IGatewayPayment'
import { KeweConsentResult, getKeweConsentResult } from '../utils/keweConsentResult';

const mapGeschlecht = (geschlecht: Gender | undefined): string => {
    if (geschlecht === Gender.MALE) {
        return 'Herr';
    } else if (geschlecht === Gender.FEMALE) {
        return 'Frau';
    }
    return '';
};

const mapEmailAddress = (email: string, eweMail: string, emailId?: string): IGatewayMail => {
    const address = email ? email : eweMail;
    return {
        address,
        type: GatewayMailType.PRIVATE,
        id: emailId
    };
};

const mapTelephoneNumber = (policyHolder: IFormPolicyHolder, phoneId?: string): IGatewayTelephoneNumber | undefined => {
    let prefix = policyHolder.vorwahl;
    let phoneNumber = policyHolder.rufnummer;

    if (prefix === '' && phoneNumber === '') {
        return undefined;
    }

    return { prefix, phoneNumber, id: phoneId };
};

const mapPayment = (iban?: string, paymentId?: string): IGatewayPayment | undefined => {
    if(!iban) return undefined;
    return {iban, id: paymentId};
};

const mapAddress = (street: string, houseNumber: string, postCode: string, city: string, id?: string): IGatewayAddress => {
    return {street, houseNumber, postCode, city , id};
};

const mapInsuredPersonsToGatewayModel = (insuredPersons: IFormInsuredPerson[], policyHolder: IFormPolicyHolder, personsGatewayInfo: IPersonGatewayInfo[]): IGatewayPerson[] => {
    return insuredPersons.map((insuredPerson, index) => {
        const address: IGatewayAddress = insuredPerson.hasDifferentAddress
            ? mapAddress(insuredPerson.strasse, insuredPerson.hausnummer, insuredPerson.plz, insuredPerson.ort, personsGatewayInfo[index +1].addressId)
            : mapAddress(policyHolder.strasse, policyHolder.hausnummer, policyHolder.plz, policyHolder.ort, personsGatewayInfo[index +1].addressId);

        return {
            formOfAddress: mapGeschlecht(insuredPerson.geschlecht),
            title: insuredPerson.titel ? insuredPerson.titel : undefined ,
            firstname: insuredPerson.vorname,
            lastname: insuredPerson.nachname,
            dateOfBirth: mapDateInputValueToString(insuredPerson.geburtsdatum),
            address,
            personId: personsGatewayInfo[index + 1].personId!,
            geburtsort: insuredPerson.geburtsort,
            staatsangehoerigkeit: insuredPerson.staatsangehoerigkeit
        };
    });
};

const mapPolicyHolderToGatewayModel = (policyHolder: IFormPolicyHolder, personsGatewayInfo: IPersonGatewayInfo[], keweConsentResult: KeweConsentResult | undefined): IGatewayPolicyHolder => {
    const adress: IGatewayAddress = {
        id: personsGatewayInfo[0].addressId,
        street: policyHolder.strasse,
        houseNumber: policyHolder.hausnummer,
        postCode: policyHolder.plz,
        city: policyHolder.ort,
    };

    return {
        formOfAddress: mapGeschlecht(policyHolder.geschlecht),
        title: policyHolder.titel ? policyHolder.titel : undefined,
        firstname: policyHolder.vorname,
        lastname: policyHolder.nachname,
        dateOfBirth: mapDateInputValueToString(policyHolder.geburtsdatum),
        address: adress,
        mailAddress: mapEmailAddress(policyHolder.email, policyHolder.emailEwe, personsGatewayInfo[0].emailId),
        phoneNumber: mapTelephoneNumber(policyHolder, personsGatewayInfo[0].phoneId),
        payment: mapPayment(policyHolder.iban, personsGatewayInfo[0].paymentId),
        personId: personsGatewayInfo[0].personId!,
        geburtsort: policyHolder.geburtsort,
        staatsangehoerigkeit: policyHolder.staatsangehoerigkeit,
        keweConsentResult
    };
};

export const mapToGatewayRequest = (
    businessId: string,
    formValues: IFormData,
    personsGatewayInfo: IPersonGatewayInfo[],
    isNewFlow: boolean,
    isKeweEnabled: boolean,
    validateAddress: boolean = false
): IGatewaySavePersons => {
    const keweConsentResult = getKeweConsentResult(isNewFlow, isKeweEnabled, formValues);

    const result: IGatewaySavePersons = {
        businessId,
        legalEntity: GatewayLegalEntity.ERGODIREKT,
        vn: mapPolicyHolderToGatewayModel(formValues.vn, personsGatewayInfo, keweConsentResult),
        vps: mapInsuredPersonsToGatewayModel(formValues.vps, formValues.vn, personsGatewayInfo),
        validateAddress
    };

    return result;
};
