import * as React from 'react';
import { Field } from 'redux-form';
import { validateName } from '../validate';
import { InputRowWrapper } from './FormElementsWrapper';
import { IMeta } from './IMeta';

const VornameField = (props: IMeta) => (
    <Field
        name={`${props.meta.scope}vorname`}
        component={InputRowWrapper}
        placeholder="Vorname"
        label="Vorname"
        validate={validateName}
        maxLength={31}
        data-component-id={`${props.meta.role}vorname`}
    />
);

export default VornameField;
