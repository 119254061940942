export const internationalIbans: Record<string, number> = {
    AD:    24,
    AT:    20,
    BE:    16,
    BG:    22,
    HR:    21,
    CH:    21,
    CY:    28,
    CZ:    24,
    DE:    22,
    DK:    18,
    EE:    20,
    ES:    24,
    FI:    18,
    FR:    27,
    GB:    22,
    GE:    22,
    GR:    27,
    HU:    28,
    IE:    22,
    IS:    26,
    IT:    27,
    LI:    21,
    LT:    20,
    LU:    20,
    LV:    21,
    MC:    27,
    NL:    18,
    NO:    15,
    PL:    28,
    PT:    25,
    RO:    24,
    SE:    24,
    SI:    19,
    SK:    24,
    SM:    27,
    ST:    25,
    SV:    28,
    TL:    23,
    TN:    24,
    TR:    26,
    UA:    29,
    VG:    24,
    XK:    20
};

export const europeanIbans: Record<string, number> = {
    AD:    24,
    AT:    20,
    BE:    16,
    BG:    22,
    HR:    21,
    CH:    21,
    CY:    28,
    CZ:    24,
    DE:    22,
    DK:    18,
    EE:    20,
    ES:    24,
    FI:    18,
    FR:    27,
    GB:    22,
    GE:    22,
    GR:    27,
    HU:    28,
    IE:    22,
    IS:    26,
    IT:    27,
    LI:    21,
    LT:    20,
    LU:    20,
    LV:    21,
    MC:    27,
    NL:    18,
    NO:    15,
    PL:    28,
    PT:    25,
    RO:    24,
    SE:    24,
    SI:    19,
    SK:    24,
    SM:    27,
    VA:    22,
};