import * as React from 'react';
import { Field } from 'redux-form';
import { InputRowWrapper } from './FormElementsWrapper';

const tooltiptext = 'Ihre IBAN finden Sie auf Ihrer Bankkarte oder auf Ihrem Kontoauszug.';

const IbanField: React.StatelessComponent = () => {
    return (
        <Field
            name="vn.iban"
            component={InputRowWrapper}
            label="IBAN"
            placeholder="IBAN"
            tooltip={tooltiptext}
            maxLength={43}
            //Making user input in upper case as SPCS only validates the IBAN numnber with capital letters.
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {e.target.value = e.target.value.replace(/[^\dA-Za-z]/g, '').replace(/(.{4})/g, '$1 ').trim().toUpperCase();}}
            data-component-id="iban"
        />
    );
};

export default IbanField;
