import * as React from 'react';

import TooltipIcon from '@eg/elements/TooltipIcon';
import GwgRadioGroupField from './GwgRadioGroupField';
import { IMeta } from './IMeta';
import './InlineTooltip.css';

interface IGwgRadioGroup {
    groupId: string;
    label: string;
    defaultValue?: boolean;
    callbackJa?: () => void;
    callbackNein?: () => void;
    tooltip?: JSX.Element;
    touch: (...field: string[]) => void;
}

const GwgRadioGroup: React.StatelessComponent<IGwgRadioGroup & IMeta> = (props) => {
     return (
            <div>
                <p data-component-id={`${props.groupId}-label`}>
                    <div className="InlineTooltip">
                        <span>{props.label}</span>&nbsp;
                        {props.tooltip &&
                        <TooltipIcon data-component-id={`${props.groupId}-tooltip`}>
                            <span data-component-id={`${props.groupId}-tooltip-text`}>{props.tooltip}</span>
                        </TooltipIcon>
                        }
                    </div>
                </p>
                <GwgRadioGroupField
                    meta={props.meta}
                    callbackJa={props.callbackJa}
                    callbackNein={props.callbackNein}
                    defaultValue={props.defaultValue}
                    groupId={props.groupId}
                    touch={props.touch}
                />

            </div>
        );
};

export default GwgRadioGroup;
