import * as React from 'react';
import Radio from '@eg/elements/Radio';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import { Field } from 'redux-form';
import { validateAnrede } from '../validate';
import Gender from '../model/Gender';
import { IMeta } from './IMeta';

interface IAnredeField {
    isReadOnly: boolean;
}

// tslint:disable-next-line:no-any
const renderAnredeField = ({input, headline, isReadOnly, label, meta: { touched, error }}: any) => {

    // we have to return a number
    // tslint:disable-next-line:no-any
    const chg = (e: React.ChangeEvent<HTMLInputElement>) => input.onChange(parseInt(e.target.value, 10));

    // initialvalues approach does not work out-of-the-box for radio buttons,
    // thus we have to help a bit with the defaultValue
    return (
        <RadioGroupRow
            label={label}
            name={input.name}
            onChange={chg}
            defaultValue={input.value}
            error={touched && error}
            disabled={isReadOnly}
        >
            <Radio
                value={Gender.MALE}
                label="Herr"
                data-component-id={`${headline}herr`}
            />
            <Radio
                value={Gender.FEMALE}
                label="Frau"
                data-component-id={`${headline}frau`}
            />
        </RadioGroupRow>
    );
};

const AnredeField = (props: IMeta & IAnredeField) => (
    <Field
        name={`${props.meta.scope}geschlecht`}
        component={renderAnredeField}
        label="Anrede"
        validate={validateAnrede}
        headline={props.meta.role}
        isReadOnly={props.isReadOnly}
        data-component-id={`${props.meta.role}geschlecht`}
    />
);

export default AnredeField;
