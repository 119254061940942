export const TEXTS = {
  TITLE: "Warum benötigt ERGO Ihre E-Mail-Adresse?",
  DESCRIPTION:
    "Ihre E-Mail-Adresse wird benötigt, um Ihnen die Anforderung per E-Mail zu bestätigen.",
  FIELD_LABEL: "E-Mail-Adresse",
  FIELD_PLACEHOLDER: "E-Mail",
} as const;

export const EMAIL_MAX_LENGTH = 60;

export const ANNOTATION_ORDER = 1;
