import { RefObject, FC,  StatelessComponent, useEffect, useRef } from 'react';
import { IMeta } from '../subcomponents/IMeta';
import PlzOrtField from '../subcomponents/PlzOrtField';
import StrasseHausnummerField from '../subcomponents/StrasseHausnummerField';
import { basicAddressErrorMsg, updatedAddressErrorMsg, addressCorrectedMsg } from  './AddressSection.styles';

interface IAddressSectionProps {
    isWohnortReadOnly: boolean;
    isGWGRequired?: boolean;
    touch: (...field: string[]) => void;
    validateAddress?: boolean;

    isValidStreetVp?: boolean;
    setValidStreetVp?: (valid: boolean) => void;
    isValidHousenumberVp?: boolean;
    setValidHousenumberVp?: (valid: boolean) => void;
    isValidPostalCityVp?: boolean;

    isValidStreetVn?: boolean;
    setValidStreetVn?: (valid: boolean) => void;
    isValidHousenumberVn?: boolean;
    setValidHousenumberVn?: (valid: boolean) => void;
    isValidPostalCityVn?: boolean;
    setValidPostalCityVn?: (valid: boolean) => void;
    isVpAddressCorrected?: boolean;
    isVnAddressCorrected?: boolean; 

    role: string;
    isNewPersonalDataFlow?: boolean;
}

enum dataComponentIdsSelectorEnum {
    street = 'strasse',
    houseNumber = "hausnummer",
    postalCode = "plz"
  };

interface IAddressNotificationMessageProps {
    divRef: RefObject<HTMLDivElement>;
    selectorForElementToBeFocused:string;
    isNewPersonalDataFlow?: boolean;
}

const AddressWarningMessage: FC<IAddressNotificationMessageProps> = ({ divRef, selectorForElementToBeFocused }) => {
    useEffect(() => {
        if (divRef.current) {
            divRef.current.scrollIntoView({ behavior: 'smooth' });
            if (selectorForElementToBeFocused !== "") {
                const firstFieldWithError = divRef.current?.querySelector<HTMLElement>(`input[data-component-id*=${selectorForElementToBeFocused}]`);
                (firstFieldWithError as HTMLElement)?.focus();
            }
        }
    }, [divRef,selectorForElementToBeFocused]);

    return (
        <div className="ee_form-row ee_form-row--wide">
            <div style={addressCorrectedMsg} data-component-id="personal-data-address-corrected-warning">
                Ihre Eingabe wurde korrigiert. Bitte prüfen Sie, ob es sich um Ihre richtige Anschrift handelt.
            </div>
        </div>
    );
};

const AddressErrorMessage: FC<IAddressNotificationMessageProps> = ({ divRef, selectorForElementToBeFocused, isNewPersonalDataFlow }) => {
    useEffect(() => {
        if (divRef.current) {
            divRef.current.scrollIntoView({ behavior: 'smooth' });
            //for focusing first field with error under divRef. Every input field has a unique data-component-id.So this attribute is used to get the element then focus it. 
            //ScrollintoView would happen in parallel. So it looks fine. dataComponentIdsEnum is hardcoded with possible values from web-ui/src/subcomponents/StrasseHausnummerField.tsx , web-ui/src/subcomponents/PlzOrtField.tsx
            if (selectorForElementToBeFocused !== "") {
                let firstFieldWithError = divRef.current?.querySelectorAll(`input[data-component-id*=${selectorForElementToBeFocused}]`)[0];
                (firstFieldWithError as HTMLElement)?.focus();
            }
        }
    }, [divRef,selectorForElementToBeFocused]);

    return (
        <div className="ee_form-row ee_form-row--wide">
            {!isNewPersonalDataFlow && (<div className="ee_form-row__descriptor ee_form-row__descriptor--wide">&nbsp;</div>)}
            <div style={isNewPersonalDataFlow ? updatedAddressErrorMsg : basicAddressErrorMsg} data-component-id="personal-data-address-corrected-message">
                Es können nur Adressen innerhalb Deutschlands akzeptiert werden.
            </div>
        </div>
    );
};

const getAddressWarningMessage = (
    props: IAddressSectionProps, 
    type: 'Vp' | 'Vn', 
    divRef: React.RefObject<HTMLDivElement>, 
    selectorForElementToBeFocused: string
  ) => {
    const isInvalid = props.isNewPersonalDataFlow && (
        type === 'Vp' ? props.isVpAddressCorrected : props.isVnAddressCorrected
      );

    return isInvalid ? (
        <AddressWarningMessage 
            divRef={divRef} 
            selectorForElementToBeFocused={selectorForElementToBeFocused} 
        />
    ) : null;
}

const getAddressErrorMessage = (
    props: IAddressSectionProps, 
    type: 'Vp' | 'Vn', 
    divRef: React.RefObject<HTMLDivElement>, 
    selectorForElementToBeFocused: string
  ) => {
    const isInvalid =
        (type === 'Vp' ? props.isValidHousenumberVp : props.isValidHousenumberVn) === false ||
        (type === 'Vp' ? props.isValidStreetVp : props.isValidStreetVn) === false ||
        (type === 'Vp' ? props.isValidPostalCityVp : props.isValidPostalCityVn) === false;

    return isInvalid ? (
      <AddressErrorMessage 
        divRef={divRef} 
        selectorForElementToBeFocused={selectorForElementToBeFocused} 
        isNewPersonalDataFlow={props.isNewPersonalDataFlow} 
      />
    ) : null;
};

const AdressSection: StatelessComponent<IAddressSectionProps & IMeta> = (props) => {
    const divRef = useRef<HTMLDivElement>(null);

    if(props.role === 'vp'){
        let selectorForElementToBeFocused = ""
        if (props.isValidStreetVp === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.street
        } else if (props.isValidHousenumberVp === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.houseNumber
        } else if (props.isValidPostalCityVp === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.postalCode
        } else {
            selectorForElementToBeFocused = ""
        }
    return (
        <div ref={divRef}>
            <StrasseHausnummerField
                meta={props.meta}
                validateAddress={props.validateAddress}
                isValidStreet={props.isValidStreetVp}
                setValidStreet={props.setValidStreetVp}
                isValidHousenumber={props.isValidHousenumberVp}
                setValidHousenumber={props.setValidHousenumberVp}
            />
            <PlzOrtField
                meta={props.meta}
                isReadOnly={props.isWohnortReadOnly}
                validateAddress={props.validateAddress}
                isValidPostalCity={props.isValidPostalCityVp}
            />
            {getAddressErrorMessage(props, 'Vp', divRef, selectorForElementToBeFocused)}
            {getAddressWarningMessage(props, 'Vp', divRef, selectorForElementToBeFocused)}
          </div>
    );
    } else {
        let selectorForElementToBeFocused = ""
        if (props.isValidStreetVn === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.street
        } else if (props.isValidHousenumberVn === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.houseNumber
        } else if (props.isValidPostalCityVn === false) {
            selectorForElementToBeFocused = dataComponentIdsSelectorEnum.postalCode
        } else {
            selectorForElementToBeFocused = ""
        }
        return (
            <div ref={divRef}>
                <StrasseHausnummerField
                    meta={props.meta}
                    validateAddress={props.validateAddress}
                    isValidStreet={props.isValidStreetVn}
                    setValidStreet={props.setValidStreetVn}
                    isValidHousenumber={props.isValidHousenumberVn}
                    setValidHousenumber={props.setValidHousenumberVn}
                />
                <PlzOrtField
                    meta={props.meta}
                    isReadOnly={props.isWohnortReadOnly}
                    validateAddress={props.validateAddress}
                    isValidPostalCity={props.isValidPostalCityVn}
                    setValidPostalCity={props.setValidPostalCityVn}
                /> 
                {getAddressErrorMessage(props, 'Vn', divRef, selectorForElementToBeFocused)}
                {getAddressWarningMessage(props, 'Vn', divRef, selectorForElementToBeFocused)}
            </div>
        );
    }
};

export default AdressSection;
