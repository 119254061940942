import * as React from 'react';
import { Field } from 'redux-form';
import SelectRow from '@eg/elements/components/SelectRow';

// tslint:disable-next-line:no-any
const AbbuchungsTageDropDown = ({input, label, name, abbuchungsTage}: any) => {
    // we have to return a number
    // tslint:disable-next-line:no-any
    const change = (event: any) => input.onChange(parseInt(event.target.value, 10));

    return (
        <SelectRow
            label={label}
            name={input.name}
            onChange={change}
            defaultValue={input.value}
            data-component-id="abbuchungs-tag"
        >
            {abbuchungsTage.map((tagImMonat: string) => (
                <option key={tagImMonat} value={tagImMonat}>{tagImMonat}. des Fälligkeitsmonats</option>)
            )}
        </SelectRow>
    );
};

interface IAbbuchungsTageField {
    abbuchungsTage?: number[];
}

const AbbuchungsTageField = (props: IAbbuchungsTageField) => (
    <Field
        name="vn.tagDerAbbuchung"
        component={AbbuchungsTageDropDown}
        label="Abbuchung am"
        abbuchungsTage={props.abbuchungsTage}
        data-component-id="abbuchungs-datum"
    />
);

export default AbbuchungsTageField;
