import { IGatewaySavePersonsResponse } from '../infrastructure/model/IGatewaySavePersonsResponse';
import InquiryType from '../messaging/model/input/InquiryType';
import { IApiError } from '../model/IApiError';
import { IFormData } from '../model/IFormData';
import { IKeweConsentValidation } from '../model/IKeweConsentValidation';
import { INationality } from '../model/INationality';
import { IPersonGatewayInfo } from '../model/IPersonGatewayInfo';

export interface IAppState {
    inquiryType?: InquiryType;
    personsGatewayInfo: IPersonGatewayInfo[];
    nationalities: INationality[];

    isValidStreetVn?: boolean;
    isValidHousenumberVn?: boolean;
    isValidPostalCityVn?: boolean;

    isValidStreetVp?: boolean;
    isValidHousenumberVp?: boolean;
    isValidPostalCityVp?: boolean;

    nextClickedValidateAddress?: boolean;
    isIbanValid: boolean;
    apiError: IApiError;

    validationResults?: IGatewaySavePersonsResponse;
    formdata?: IFormData;

    keweConsent?: IKeweConsentValidation;
}

export const createInitialKeweConsentState = (
    isNewFlow: boolean,
    isKeweEnabled: boolean,
    isEmailEwe: boolean | undefined
): IKeweConsentValidation | undefined => {
    if (!isNewFlow || !isKeweEnabled) {
        return undefined;
    }

    return {
        hideKeweContactSection: false,
        hideKeweEmailField: !isEmailEwe,
    };
};

export const createInitialState = (
    personsGatewayInfo: IPersonGatewayInfo[],
    keweConsent: IKeweConsentValidation | undefined
): IAppState => {
    const initialState: IAppState = {
        personsGatewayInfo: personsGatewayInfo,
        nationalities: [],
        nextClickedValidateAddress: false,
        isIbanValid: false,
        apiError: {},
        isValidPostalCityVn: true,
        isValidStreetVn: true,
        isValidHousenumberVn: true,
        isValidPostalCityVp: true,
        isValidStreetVp: true,
        isValidHousenumberVp: true,
        validationResults: { persons: [] },
        formdata: {
            vn: {
                email: '',
                emailEwe: '',
                rufnummer: '',
                vorwahl: '',
                iban: '',
                bic: '',
                abbuchungsTage: [],
                vorname: '',
                nachname: '',
                geburtsort: '',
                strasse: '',
                hausnummer: '',
                plz: '',
                ort: '',
                geburtsdatum: {},
            },
            vps: [],
        },
    };

    if (keweConsent) {
        initialState.keweConsent = keweConsent;
    }

    return initialState;
};
