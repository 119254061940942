import * as React from 'react';
import Radio from '@eg/elements/Radio';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import { Field } from 'redux-form';
import {
    validateRadioButtonGwg
} from '../validate';
import { IMeta } from './IMeta';

interface IGwgAnredeGroupField {
    groupId: string;
    defaultValue?: boolean;
    callbackJa?: () => void;
    callbackNein?: () => void;
    touch: (...field: string[]) => void;
}

// tslint:disable-next-line:no-any
const renderGwgAnredeGroupField = ({input, defaultValue, touch, callbackJa, callbackNein, meta: {touched, error}}: any) => {

    // tslint:disable-next-line:no-any
    const chg = (e: React.ChangeEvent<HTMLInputElement>) => {

        // issue in redux-form, see https://github.com/erikras/redux-form/issues/2580
        // setting touchOnChange to true on the form is not an option because it affects all form fields.
        // so the workaround is to pass down a function that triggers dispatch of touch action and to call it from here.
        // the workaround should be removed one day when redux-form has addresses this issue
        touch(input.name);
        input.onChange(e);
    };

    // initialvalues approach does not work out-of-the-box for radio buttons,
    // thus we have to help a bit with the defaultValue
    return (
        <RadioGroupRow label="" onChange={chg} name={`${input.name}`} error={touched && error}>
            <Radio
                data-component-id={`${input.name}-true`}
                value={true}
                label="ja"
                onChange={callbackJa}
                checked={input.value === 'true' || input.value  === true}
            />
            <Radio
                data-component-id={`${input.name}-false`}
                value={false}
                label="nein"
                onChange={callbackNein}
                checked={input.value === 'false' || input.value  === false}
            />
        </RadioGroupRow>
    );
};

const GwgRadioGroupField = (props: IMeta & IGwgAnredeGroupField) => {

    // Due to issue in redux forms you can not pass wrapped function to validate
    // https://github.com/erikras/redux-form/issues/2453

    return (
        <Field
            name={`${props.meta.scope}${props.groupId}`}
            component={renderGwgAnredeGroupField}
            validate={validateRadioButtonGwg}
            defaultValue={props.defaultValue}
            data-component-id={`${props.meta.role}${props.groupId}`}
            callbackJa={props.callbackJa}
            callbackNein={props.callbackNein}
            touch={props.touch}
        />
    );
};

export default GwgRadioGroupField;
