import React, { useState, useEffect } from "react";
import { Field } from "redux-form";
import { validateEmailAsMandatory, validateEmailAsOptional } from "../validate";
import { InputRowWithAnnotedLabel } from "./FormElementsWrapper";
import Annotation from "./Annotation";
import { ANNOTATION_ORDER, EMAIL_MAX_LENGTH, TEXTS } from "./EmailField.const";
import "./EmailField.css";
import { EMAIL_KEWE_DATA_COMPONENT_ID } from "./KEwe/KEweConsentSection.const";

const EmailField = () => {
  const [isValidationRequired, updateValidationRequired] = useState(false);

  const handleNextClick = () => {
    if (!isValidationRequired) {
      updateValidationRequired(true);
    }
  };

  useEffect(() => {
    document.addEventListener("nextClicked", handleNextClick);
    if (isValidationRequired) {
      return document.removeEventListener("nextClicked", handleNextClick);
    }
  }, [handleNextClick]);

  return (
    <>
      <hr />

      <h3 className="email-field-header">{TEXTS.TITLE}</h3>

      <div>
        <p className="email-field-desc">{TEXTS.DESCRIPTION}</p>

        <Field
          name="email"
          component={InputRowWithAnnotedLabel}
          label={
            <>
              {TEXTS.FIELD_LABEL}
              <Annotation order={ANNOTATION_ORDER} />
            </>
          }
          placeholder={TEXTS.FIELD_PLACEHOLDER}
          validate={
            isValidationRequired
              ? validateEmailAsMandatory
              : validateEmailAsOptional
          }
          maxLength={EMAIL_MAX_LENGTH}
          data-component-id={EMAIL_KEWE_DATA_COMPONENT_ID}
        />
      </div>
    </>
  );
};

export default EmailField;
