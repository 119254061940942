import * as React from 'react';
import ControlWithHint from '@eg/elements/components/ControlWithHint';
import InputRow from '@eg/elements/components/InputRow';
import Input from '@eg/elements/components/Input';
import DateInputRow from '@eg/elements/components/DateInputRow';
import { EMAIL_KEWE_DATA_COMPONENT_ID } from './KEwe/KEweConsentSection.const';

// solution with wrapper functions see https://redux-form.com/7.1.2/examples/material-ui/

// tslint:disable-next-line:no-any
const ControlWrapper = ({input, tooltiptext, label, disabled, meta: {touched, error}, ...custom}: any) => (
    <ControlWithHint error={touched && error}>
        <Input
            label={label}
            {...input}
            tooltip={tooltiptext}
            readOnly={disabled}
            {...custom}
        />
    </ControlWithHint>
);

// tslint:disable-next-line:no-any
const InputRowWrapper = ({input, tooltiptext, label, disabled, meta: {touched, error}, ...custom}: any) => (
    <InputRow
        label={label}
        {...input}
        error={touched && error}
        tooltip={tooltiptext}
        readOnly={disabled}
        {...custom}
    />
);

// tslint:disable-next-line:no-any
const InputRowWithAnnotedLabel = ({input, label, meta: {touched, error}, ...custom}: any) => {
   const displayError = custom['data-component-id'] === EMAIL_KEWE_DATA_COMPONENT_ID ? error : touched && error;
   return <InputRow
        label={label}
        {...input}
        error={displayError}
        {...custom}
    />
};

// tslint:disable-next-line:no-any
const InputWithHintWrapper = ({input, meta: {touched, error}, ...custom}: any) => (
    <ControlWithHint error={touched && error}>
        <Input {...input} {...custom}  />
    </ControlWithHint>
);

const minDate = new Date();
minDate.setFullYear(new Date().getFullYear() - 100);

const maxDate = new Date();

// tslint:disable-next-line:no-any
const DateInputRowWrapper = ({input, label, meta: {touched, error}, disabled, ...custom}: any) => (
    <DateInputRow
        label={label}
        minDate={minDate}
        maxDate={maxDate}
        autoTab
        {...input}
        error={touched && error}
        disabled={disabled}
        {...custom}
    />
);

export { InputRowWrapper, InputRowWithAnnotedLabel, InputWithHintWrapper, DateInputRowWrapper, ControlWrapper };
