import * as React from 'react';

interface IErrorBoundaryProps {
    onError: (e: Error) => void;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps> {

    componentDidCatch(error: Error) {
        this.props.onError(error);
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;