import * as React from 'react';
import { Field } from 'redux-form';
import { validateEmailAsMandatory, validateEmailAsOptional } from '../validate';
import { InputRowWrapper } from './FormElementsWrapper';
import { IMeta } from './IMeta';

export interface IOnetimeEmailFieldProps {
    isOptional: boolean;
    showEmailConfirmationTooltip: boolean;
}

const tooltiptext = 'Wir bzw. der Versicherer benötigen bitte Ihre E-Mail-Adresse, um Ihnen die Anforderung per E-Mail zu bestätigen. ' +
    'Diese Angabe unterliegt selbstverständlich unseren strengen Datenschutz-Bestimmungen!';

const OnetimeEmailField: React.StatelessComponent<IOnetimeEmailFieldProps & IMeta> = (props) => {
    return (
      <Field
        name={`${props.meta.scope}email`}
        component={InputRowWrapper}
        label="E-Mail"
        placeholder="E-Mail-Adresse"
        validate={props.isOptional ? validateEmailAsOptional : validateEmailAsMandatory}
        maxLength={60}
        {...{tooltiptext: props.showEmailConfirmationTooltip ? undefined : tooltiptext}}
        data-component-id="email"
      />
    );
}

export default OnetimeEmailField;
