import { DateValidation } from '../model/DateValidation';
import { IFormData } from '../model/IFormData';
import * as validation from '../validate';
import { IStaticPersonalData } from '../model/IStaticPersonalData';
import { IKeweConsentValidation } from '../model/IKeweConsentValidation';
export interface IValidationResults {
    isPartOneValid: boolean;
    isAngebotMoeglich: boolean;
    isAbschlussMoeglich: boolean;
    isPromotionSuccessful: boolean;
    isEmailOptionalValid: boolean;
    isEmailMandatoryValid: boolean;
    keweConsent: IKeweConsentValidation;
}

const getVpsGeburtsdatumValidation = (staticPersonalData: IStaticPersonalData) =>
    staticPersonalData.vps.map(vp => vp.geburtsdatumValidation as DateValidation);

const getFuehrerscheinDatumValidation = (staticPersonalData: IStaticPersonalData) =>
    staticPersonalData.vn.fuehrerschein
        ? staticPersonalData.vn.fuehrerschein.datumValidation
        : undefined;

export const getValidationResults = (values: IFormData, staticPersonalData: IStaticPersonalData, isIbanValid: boolean, ibanAlwaysVisible: boolean): IValidationResults => {
    const validate = (f: validation.validationFx) => f(
        values,
        isIbanValid,
        ibanAlwaysVisible,
        getVpsGeburtsdatumValidation(staticPersonalData),
        staticPersonalData.vn.isEmailOptional,
        staticPersonalData.disableEwe,
        getFuehrerscheinDatumValidation(staticPersonalData),
        staticPersonalData
    );

    return {
        isPartOneValid: validate(validation.isPartOneValid),
        isAngebotMoeglich: validate(validation.isAngebotMoeglich),
        isAbschlussMoeglich: validate(validation.isAbschlussMoeglich),
        isPromotionSuccessful: validate(validation.isPromotionSuccessful),
        isEmailOptionalValid: validation.isEMailValid(values, true),
        isEmailMandatoryValid: validation.isEMailValid(values, false),
        keweConsent: validation.isKeweConsentSet(values, ibanAlwaysVisible, isIbanValid),
    };
};
