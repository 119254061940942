import { INationality } from './INationality';

const getNationalityByKey = (nationalityKey: string | undefined, nationalityList: INationality[]): INationality | undefined => {

    return nationalityList.find(nationalityElement => nationalityElement.key === nationalityKey);

};

const getNationalityByValue = (nationalityValue: string | undefined, nationalityList: INationality[]): INationality | undefined => {

    return nationalityList.find(nationalityElement => nationalityElement.label === nationalityValue);
};
export {
    getNationalityByKey,
    getNationalityByValue
};
