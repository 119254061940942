import * as React from 'react';
import { FormSection } from 'redux-form';
import { IVnWithMappings } from '../Form';
import { INationality } from '../model/INationality';
import { IStaticPolicyHolder } from '../model/IStaticPersonalData';
import AdressSection from '../sections/AddressSection';
import FuehrerscheinSection from '../sections/FuehrerscheinSection';
import PersonSection from '../sections/PersonSection';
import Headline from './Headline';
import Kontaktdaten from './Kontaktdaten';
import KontaktdatenEwe from './KontaktdatenEwe';

export interface IVnCommonProps {
    staticData: IStaticPolicyHolder;
    dynamicData: IVnWithMappings;
    touch: (...field: string[]) => void;
    nationalities: INationality[];
    validateAddress?: boolean;
    showTitel: boolean;
    isValidStreetVn?: boolean;
    setValidStreetVn?: (valid: boolean) => void;
    isValidHousenumberVn?: boolean;
    setValidHousenumberVn?: (valid: boolean) => void;
    isValidPostalCityVn?: boolean;
    setValidPostalCityVn?: (valid: boolean) => void;
}

export interface IVnProps extends IVnCommonProps {
    disableEwe?: boolean;
}

const Vn: React.StatelessComponent<IVnProps> = (props: IVnProps) => {
    const kontaktdaten: JSX.Element = props.disableEwe
        ? <Kontaktdaten isEmailOptional={props.staticData.isEmailOptional} showEmailConfirmationTooltip={props.staticData.showEmailConfirmationTooltip}/>
        : <KontaktdatenEwe/>;

    return (
        <FormSection name="vn">
            <Headline text={props.staticData.ueberschrift}/>
            {/* next 2 lines: the scope does not have to be explicitely passed, it is defined by the name of the form section
                                    (in contrast to how it works for the VP FieldArray where the scope has to be explicitely defined) */}
            <PersonSection
                meta={{scope: '', role: ''}}
                isGeburtsdatumWriteable={false}
                isSexReadOnly={props.staticData.isSexReadOnly}
                isGWGRequired={props.staticData.isGWGRequired}
                showStaatsangehoerigkeit={props.staticData.showStaatsangehoerigkeit}
                showGeburtsort={props.staticData.showGeburtsort}
                touch={props.touch}
                nationalities={props.nationalities}
                showTitel={props.showTitel}
            />
            <AdressSection
                meta={{scope: '', role: ''}}
                isWohnortReadOnly={props.dynamicData.isWohnortReadOnly}
                isGWGRequired={props.staticData.isGWGRequired}
                validateAddress={props.validateAddress}
                isValidStreetVn={props.isValidStreetVn}
                setValidStreetVn={props.setValidStreetVn}
                isValidHousenumberVn={props.isValidHousenumberVn}
                setValidHousenumberVn={props.setValidHousenumberVn}
                isValidPostalCityVn={props.isValidPostalCityVn}
                setValidPostalCityVn={props.setValidPostalCityVn}
                touch={props.touch}
                role = {'vn'}
            />
            {kontaktdaten}

            {props.staticData.fuehrerschein &&

                <FuehrerscheinSection
                    datumValidation={props.staticData.fuehrerschein.datumValidation}
                    touch={props.touch}
                    isLicenseDateReadonly={props.staticData.fuehrerschein.isLicenseDateReadonly}
                />
            }
        </FormSection>
    );
};

export default Vn;
