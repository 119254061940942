import * as React from 'react';
import { Field } from 'redux-form';
import SelectRow from '@eg/elements/components/SelectRow';
import { IMeta } from './IMeta';
import { validateNationality } from '../validate';
import { INationality } from '../model/INationality';
import { getNationalityByValue } from '../model/Nationalities';
// tslint:disable-next-line:no-any
const NationalityDropDown = ({input, label, name, dataComponentId, nationalities, touch, meta: {touched, error}, ...custom}: any) => {
    // tslint:disable-next-line:no-any
    const chg = (e: React.ChangeEvent<HTMLInputElement>) => {
        // issue in redux-form, see https://github.com/erikras/redux-form/issues/2580
        // setting touchOnChange to true on the form is not an option because it affects all form fields.
        // so the workaround is to pass down a function that triggers dispatch of touch action and to call it from here.
        // the workaround should be removed one day when redux-form has addresses this issue
        touch(input.name);
        input.onChange(e.target.value);
    };

    const nationality = getNationalityByValue(input.value, nationalities);
    const value = nationality ? nationality.key : input.value;

    const favouriteNationalityKeys = ['DE', 'CH', 'AT', 'PL'];
    const favouriteNationalities: INationality[] = nationalities.filter((n: INationality) => favouriteNationalityKeys.find(k => k === n.key));
    const filteredNationalities: INationality[] = nationalities.filter((n: INationality) => !favouriteNationalityKeys.find(k => k === n.key));

    return (
        <SelectRow
            label={label}
            name={input.name}
            onChange={chg}
            defaultValue={''}
            error={touched && error}
            data-component-id={dataComponentId}
            value={value}
            {...custom}
        >
            <option value={''}>Bitte wählen</option>
            {favouriteNationalities.map((nation: INationality) => (
                <option key={nation.key} value={nation.key}>{nation.label}</option>)
            )}
            <option disabled={true} key={'seperator'} value={'---------'}>----------</option>
            {filteredNationalities.map((nation: INationality) => (
                <option key={nation.key} value={nation.key}>{nation.label}</option>)
            )}
        </SelectRow>
    );
};

interface INationalityDropDownFieldProps extends IMeta {
    touch: (...field: string[]) => void;
    nationalities: INationality[];
}

const NationalityDropDownField = (props: INationalityDropDownFieldProps) => {
    return (
        <Field
            name={`${props.meta.scope}staatsangehoerigkeit`}
            component={NationalityDropDown}
            label="Staatsangehörigkeit"
            validate={validateNationality}
            touch={props.touch}
            dataComponentId={`${props.meta.role}staatsangehoerigkeit`}
            nationalities={props.nationalities}
        />
    );
};

export default NationalityDropDownField;
