import * as React from 'react';
import { IMeta } from './IMeta';
import { Field } from 'redux-form';
import Checkbox from '@eg/elements/Checkbox';

interface ICheckboxFieldProps {
    cleanAddress: () => void;
}

// tslint:disable-next-line:no-any
const renderCheckbox = ({ input, label, ...custom }: any) => (
    <Checkbox
        label={label}
        {...custom}
        defaultChecked={input.value}
        // tslint:disable-next-line:no-any
        onChange={(e: any) => input.onChange(e.target.checked)}
        variant="asFormRow"
    />
);

const CheckboxField = (props: ICheckboxFieldProps & IMeta) => (
    <Field
        name={`${props.meta.scope}hasDifferentAddress`}
        component={renderCheckbox}
        label="Andere Adresse als Versicherungsnehmer?"
        onChange={props.cleanAddress}
        data-component-id={`${props.meta.role}checkbox-andere-adresse`}
    />
);

export default CheckboxField;