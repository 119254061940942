import { IAppInput } from './messaging/model/input/IAppInput';
import { IInputVp } from './messaging/model/input/IInputVp';

const geburtsdatum = ['geburtsdatum'];
const nameAndSexFields = ['geschlecht', 'vorname', 'nachname'];
const gwgFields = ['geburtsort', 'staatsangehoerigkeit', 'gwgoptions.wirtschaftlicheigentverantwortlich'];
const phoneFields = ['vorwahl', 'rufnummer'];
const streetFields = ['strasse', 'hausnummer'];
const cityFields = ['plz', 'ort'];
const fsFields = ['fuehrerschein.datum', 'fuehrerschein.klasse'];
const bankverbindungFields = ['vn.iban', 'vn.bic'];

const getAllPartOneFieldnames = (vpCount: number, disableEwe?: boolean, gwgRequired?: boolean, staatsangehoerigkeitRequired?: boolean, geburtsortRequired?: boolean) => {
    let fields: string[] = nameAndSexFields
        .concat(streetFields)
        .concat(cityFields)
        .concat(phoneFields)
        .concat(disableEwe ? ['email'] : ['emailEwe'])
        .concat(fsFields);
    if (gwgRequired) {
        fields = fields.concat(gwgFields);
    }
    if (staatsangehoerigkeitRequired) {
        fields.push('staatsangehoerigkeit')
    }
    if (geburtsortRequired) {
        fields.push('geburtsort');
    }

    fields = fields.map(field => `vn.${field}`);

    for (let i = 0; i < vpCount; i++) {
        fields.push(...nameAndSexFields.concat(geburtsdatum).concat(streetFields).concat(cityFields).map(item => `vps[${i}].${item}`));
    }

    return fields;
};

const getOneTimeEmailFieldName = (disableEwe?: boolean) => {
    return !disableEwe ? ['vn.email'] : [];
};

const getBankdatenFieldNames = () => {
    return bankverbindungFields;
}

const getVpStreetFieldnames = (index: number) => streetFields.map(field => `vps[${index}].${field}`);

const getVpCityFieldnames = (index: number) => cityFields.map(field => `vps[${index}].${field}`);

const getFilledWriteableDateFields = (input: IAppInput) => {
    const result: Array<string> = [];

    if (input.vn.isGWGRequired) {
        result.push('vn.gwgoptions.wirtschaftlicheigentverantwortlich');
    }

    if (input.vn.fuehrerschein && input.vn.fuehrerschein.datum) {
        result.push('vn.fuehrerschein.datum');
    }
    if (input.vps) {
        (input.vps as Array<IInputVp>).forEach((vp: IInputVp, index: number) => {
            if (vp.isGeburtsdatumWriteable && vp.geburtsdatum) {
                result.push(`vps[${index}].geburtsdatum`);
            }
        });
    }
    return result;
};

export {
    getAllPartOneFieldnames,
    getVpStreetFieldnames,
    getVpCityFieldnames,
    getOneTimeEmailFieldName,
    getBankdatenFieldNames,
    getFilledWriteableDateFields
};
