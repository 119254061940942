import * as React from 'react';

const InfoIbanBelow = () => {
    return (
        <p>
            Sollte bereits ein Mandat für die oben genannte Kontoverbindung
            bestehen, bin ich damit einverstanden, dass das von mir bereits erteilte
            SEPA&#8209;Lastschriftmandat auch für den Einzug der Beiträge für diesen
            Versicherungsvertrag genutzt wird.
        </p>
    );
};

export default InfoIbanBelow;
