import { IFormData } from './model/IFormData';
import { IMergedFormProps } from './Form';
import * as ibans from './utils/ibanLengths';

const TYPES_OF_VALIDATION: {
    [key in keyof typeof ibans]: {
        validatingBIC: boolean,
        ibanWrongIban: string,
        ibanNotExist: string,
    }
} = {
    'internationalIbans': {
        validatingBIC: true,
        ibanWrongIban: 'Bitte geben Sie eine gültige IBAN ein.',
        ibanNotExist: 'Bitte geben Sie eine gültige IBAN ein.',
    },
    'europeanIbans': {
        validatingBIC: false,
        ibanWrongIban: 'Bitte geben Sie eine gültige IBAN ein.',
        ibanNotExist: 'Bitte geben Sie eine IBAN von einer Bank aus dem SEPA-Zahlungsraum an.'
    }

}

const validateInternationalIban = (type: keyof typeof ibans, countryCode: string, sanitizedIban: string, bic?: string) => {
    let ibanErrorMsg: string | undefined;
    let bicErrorMsg: string | undefined;

    if (!ibans[type][countryCode]) {
        ibanErrorMsg = TYPES_OF_VALIDATION[type].ibanNotExist;
    } else {
        if (ibans[type][countryCode] !== sanitizedIban.length) {
            ibanErrorMsg = TYPES_OF_VALIDATION[type].ibanWrongIban;
        }
    }

    // Prüfung auf BIC
    if (TYPES_OF_VALIDATION[type].validatingBIC && countryCode !== 'DE') {
        if (!bic || !/^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(bic.replace(/ /g, ''))) {
            bicErrorMsg = 'Bitte geben Sie eine gültige BIC ein.';
        }
    }

    return [ibanErrorMsg, bicErrorMsg];
}

export const asyncValidateIbanAndBic = async (values: IFormData, dispatch: any, props: IMergedFormProps, blurredField: string | undefined) => {
    if (!props.isBankdatenVisible) {
        return;
    }

    const triggerOnIbanValidated = (isValid: boolean) => {
        // trigger the PersonendatenChange callback only if iban/bic change is the cause of the validation
        // (note that redux forms is performing the validation also on form submit (at least in the default implementation of shouldAsyncValidate) and
        // in this cases we do not want to emit unnecessary change notifications to the consumer)
        props.onIbanValidated(values, isValid, Boolean(blurredField));
    };

    let ibanErrorMsg: string | undefined;
    let bicErrorMsg: string | undefined;

    const sanitizedIban = values.vn.iban.replace(/ /g, '');
    const bic = values.vn.bic;

    if (sanitizedIban.length < 2) {
        ibanErrorMsg =  'Bitte geben Sie eine gültige IBAN ein.'
    } else {
        const countryCode = sanitizedIban.substr(0, 2).toUpperCase();

        if (!props.staticPersonalData.vn.hasInternationalIbanSupport && !props.staticPersonalData.vn.hasEuropeanIbanSupport && countryCode !== 'DE') {
            ibanErrorMsg = 'Bitte geben Sie eine gültige deutsche IBAN ein. Diese muss mit DE beginnen.';
        } else {
            const ibanSets = props.staticPersonalData.vn.hasInternationalIbanSupport ? 'internationalIbans' : 'europeanIbans';
            [ibanErrorMsg, bicErrorMsg] = validateInternationalIban(ibanSets, countryCode, sanitizedIban, bic);
        }
    }

    if (ibanErrorMsg || bicErrorMsg) {
        triggerOnIbanValidated(false);

        // throw for redux forms
        // eslint-disable-next-line no-throw-literal
        throw {
            vn: {
                iban: ibanErrorMsg,
                bic: bicErrorMsg
            }
        };
    }

    // we only validate on the server if no client-side errors have occurred
    const isIbanValid = await props.api.getIbanValidity(sanitizedIban, bic);
    triggerOnIbanValidated(isIbanValid);
    if (!isIbanValid) {
        // eslint-disable-next-line no-throw-literal
        throw {vn: {iban: props.staticPersonalData.vn.hasEuropeanIbanSupport ? 'Diese IBAN ist nicht korrekt. Bitte korrigieren Sie Ihre Angabe.' : 'Bitte geben Sie eine gültige IBAN ein.'}}
    }
};