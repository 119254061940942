import * as React from 'react';
import { ComponentType } from 'react';
import { FieldArray, GenericFieldArray, WrappedFieldArrayProps } from 'redux-form';
import Vp, { IVpProps } from './Vp';

interface IVpFormProps {
    vps: IVpProps[];
    isNewPersonalDataFlow?: boolean;
}

type AllProps = WrappedFieldArrayProps<{}> & IVpFormProps;

const renderVps: React.FunctionComponent<AllProps> = (props) => {
    return (<>
    {props.fields.map((scope: string, i: number) => {
        const item = props.vps[i];

        if (!item) {
            return null;
        }
        return (
            <Vp
                key={scope}
                meta={{scope, role: item.headline + '-'}}
                headline={item.headline}
                hasDifferentAddress={item.hasDifferentAddress}
                cleanAddress={item.cleanAddress}
                isWohnortReadOnly={item.isWohnortReadOnly}
                isGeburtsdatumWriteable={item.isGeburtsdatumWriteable}
                geburtsdatumValidation={item.geburtsdatumValidation}
                isGWGRequired={item.isGWGRequired}
                showStaatsangehoerigkeit={item.showStaatsangehoerigkeit}
                showGeburtsort={item.showGeburtsort}
                touch={item.touch}
                nationalities={item.nationalities}
                showTitel={item.showTitel}
                isSexReadOnly={item.isSexReadOnly}
                isValidStreetVp={item.isValidStreetVp}
                isValidHousenumberVp={item.isValidHousenumberVp}
                isValidPostalCityVp={item.isValidPostalCityVp}
                role = {'vp'}
                validateAddress = {item.validateAddress}
                vpAddressCorrected={item.vpAddressCorrected}
                isNewPersonalDataFlow={props.isNewPersonalDataFlow}  
            />
        );
    })}
</>);
};

// see https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/redux-form/redux-form-tests.tsx
const FieldArrayCustom = FieldArray as new () => GenericFieldArray<{}, IVpFormProps>;

const Vps = (props: IVpFormProps) => {
    return (
        <div>
            <FieldArrayCustom name="vps" component={renderVps as ComponentType<AllProps>} vps={props.vps} isNewPersonalDataFlow={props.isNewPersonalDataFlow} />
        </div>
    );
};

export default Vps;
