import * as React from 'react';
import { Field } from 'redux-form';
import { validateEmailAsOptional } from '../validate';
import { InputRowWithAnnotedLabel } from './FormElementsWrapper';

const EmailEweField = () => {
    return (
        <Field
            name="emailEwe"
            component={InputRowWithAnnotedLabel}
            label="E-Mail-Adresse"
            placeholder="E-Mail"
            validate={validateEmailAsOptional}
            maxLength={60}
            data-component-id="email-ewe"
        />
    );
};

export default EmailEweField;
