import * as React from 'react';
import { ITextStatic } from '../model/IStaticAppValues';

export interface IInfoIbanTopProps {
    text: ITextStatic;
}
const InfoIbanTop = (props: IInfoIbanTopProps) => {
    const h4Style = { marginTop: '1.33em', fontSize: '16px', fontWeight: 'bold' as 'bold' };

    return (
        <>
            <h3 style={{ margin: '1em 0', fontSize: '16px', fontWeight: 'bold'}}>Ihre Bankverbindung</h3>
            <p>
                Für einen Online-Abschluss benötigt der Versicherer ein SEPA-Lastschriftmandat.
            </p>

            <h4 style={h4Style}>SEPA-Lastschriftmandat</h4>
            <p data-component-id="creditor-ident" style={{ fontWeight: 700, marginBottom: '1em' }}>
                Ich ermächtige die {props.text.creditor} (Gläubiger-ID {props.text.creditorIdentNumber}), Zahlungen
                von meinem Konto mittels Lastschrift einzuziehen.
            </p>
            <p>
                Zugleich weise ich mein Kreditinstitut an, diese auf mein Konto
                gezogenen Lastschriften einzulösen. Der SEPA-Basislastschrift-Einzug
                wird mir spätestens 5 Kalendertage im Voraus unter Angabe der weiteren
                Fälligkeitstermine angekündigt.
            </p>

            <h4 style={h4Style}>Hinweis</h4>
            <p>
                Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, die
                Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit
                meinem Kreditinstitut vereinbarten Bedingungen.
            </p>
        </>
    );
};

export default InfoIbanTop;
