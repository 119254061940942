import * as React from 'react';
import FormRow from '@eg/elements/components/FormRow';
import Input from '@eg/elements/components/Input';

interface IIbanZusatzdatenFieldProps {
    fullName: string;
    datum?: string;
    showDatum?: boolean;
}

const IbanZusatzdatenField: React.StatelessComponent<IIbanZusatzdatenFieldProps> = (props) => {
    return (
        <>
            {props.showDatum &&
            <FormRow label="Datum">
                <Input
                    value={props.datum}
                    readOnly={true}
                    data-component-id="iban-datum"
                />
            </FormRow>
            }
            <FormRow label="Kontoinhaber">
                <Input
                    value={props.fullName}
                    readOnly={true}
                    data-component-id="iban-kontoinhaber"
                />
            </FormRow>
        </>
    );
};

export default  IbanZusatzdatenField;
