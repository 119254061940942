import * as React from 'react';
import AnredeField from '../subcomponents/AnredeField';
import TitelField from '../subcomponents/TitelField';
import VornameField from '../subcomponents/VornameField';
import NachnameField from '../subcomponents/NachnameField';
import GeburtstagField from '../subcomponents/GeburtstagRow';
import { IMeta } from '../subcomponents/IMeta';
import { DateValidation } from '../model/DateValidation';
import GeburtsortField from '../subcomponents/GeburtsortField';
import NationalityDropDownField from '../subcomponents/NationalityDropDownField';
import { INationality } from '../model/INationality';

interface IPersonSectionProps {
    isGeburtsdatumWriteable: boolean;
    isSexReadOnly: boolean;
    geburtsdatumValidation?: DateValidation;
    isGWGRequired?: boolean;
    showStaatsangehoerigkeit?: boolean;
    showGeburtsort?: boolean;
    touch: (...field: string[]) => void;
    nationalities: INationality[];
    showTitel: boolean;
}

const PersonSection: React.StatelessComponent<IPersonSectionProps & IMeta> = props => (
    <div>
        <GeburtstagField
            meta={props.meta}
            isWriteable={props.isGeburtsdatumWriteable}
            validation={props.geburtsdatumValidation}
        />
        <AnredeField
            meta={props.meta}
            isReadOnly={props.isSexReadOnly}
        />
        { props.showTitel &&
        <TitelField
            meta={props.meta}
        />
        }
        <VornameField meta={props.meta}/>
        <NachnameField meta={props.meta}/>
        {(props.isGWGRequired || props.showGeburtsort) && <GeburtsortField meta={props.meta}/>}
        {(props.isGWGRequired || props.showStaatsangehoerigkeit) &&
            <NationalityDropDownField meta={props.meta} touch={props.touch} nationalities={props.nationalities}/>}
    </div>
);

export default PersonSection;
