import * as React from 'react';
import FormRow from '@eg/elements/FormRow';
import { Field } from 'redux-form';
import { validateOrt, validateOrtPds, validatePlz, validatePlzPds } from '../validate';
import { ControlWrapper } from './FormElementsWrapper';
import { IMeta } from './IMeta';

interface IPlzOrtFieldProps {
    isReadOnly: boolean;
    validateAddress?: boolean;
    isValidPostalCity?: boolean;
    setValidPostalCity?: (valid: boolean) => void;
}

const PlzOrtField: React.StatelessComponent<IPlzOrtFieldProps & IMeta> = (props) => {
    return (
        <FormRow label="PLZ/Ort" colspans={[1, 2]}>
            <Field
                name={`${props.meta.scope}plz`}
                component={ControlWrapper}
                maxLength={5}
                placeholder="PLZ"
                validate={props.validateAddress ? validatePlzPds(props.isValidPostalCity) : validatePlz}
                disabled={props.isReadOnly}
                data-component-id={`${props.meta.role}plz`}
                onBlur={() => {
                    if (props.validateAddress && !props.isValidPostalCity) {
                        if (props.setValidPostalCity) {
                            props.setValidPostalCity(true);
                        }
                    }
                }}
            />
            <Field
                name={`${props.meta.scope}ort`}
                component={ControlWrapper}
                placeholder="Ort"
                validate={props.validateAddress ? validateOrtPds(props.isValidPostalCity) : validateOrt}
                maxLength={30}
                disabled={props.isReadOnly}
                data-component-id={`${props.meta.role}ort`}
                onBlur={() => {
                    if (props.validateAddress && !props.isValidPostalCity) {
                        if (props.setValidPostalCity) {
                            props.setValidPostalCity(true);
                        }
                    }
                }}
            />
        </FormRow>
    );
};

export default PlzOrtField;
