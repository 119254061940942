import * as React from 'react';
import EmailEweField from './EmailEweField';
import TelefonField from './TelefonField';
import Annotation from './Annotation';

const KontaktdatenEwe: React.StatelessComponent = () => {
    return (
        <>
            <EmailEweField />
            <TelefonField label={<>Telefon<Annotation order={1}/></>}/>
        </>
    );
};

export default KontaktdatenEwe;
