import * as React from 'react';
import FuehrerscheindatumRow from '../subcomponents/FuehrerscheindatumRow';
import { DateValidation } from '../model/DateValidation';

interface IFuehrerscheinSectionProps {
    datumValidation: DateValidation;
    touch: (...field: string[]) => void;
    isLicenseDateReadonly: boolean;
}

const FuehrerscheinSection: React.FunctionComponent<IFuehrerscheinSectionProps> = props => (
    <div>
        <FuehrerscheindatumRow validation={props.datumValidation} isLicenseDateReadonly={props.isLicenseDateReadonly}/>
    </div>
);

export default FuehrerscheinSection;
