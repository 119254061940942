import * as React from 'react';
import * as ReactDOM from 'react-dom';
import AnnotationContainer from './AnnotationContainer';
import Annotation from './Annotation';

class AnnotationPortal extends React.Component {

    render() {
        const container = document.getElementById('annotation-text') as HTMLElement;

        if (container === null) {
            return null;
        }

        return ReactDOM.createPortal(
            <AnnotationContainer
                annotations={[
                    <>
                        Mit meiner freiwilligen Eingabe willige ich ein, dass mich Unternehmen und Vermittler der
                        ERGO Group<Annotation order={2}/>&nbsp;künftig per elektronischer Post und Telefon über
                        Versicherungsprodukte informieren. Sie können mich auch zur Kundenbefragung und zur Vereinbarung
                        eines
                        persönlichen Termins kontaktieren. Meine Daten dürfen hierfür erhoben, verarbeitet und genutzt
                        werden.
                        Diese Einwilligung gilt unabhängig davon, ob ein Vertrag besteht. Ich kann sie jederzeit formlos
                        für die Zukunft widerrufen.
                    </>,
                    `ERGO Versicherung AG, ERGO Direkt Krankenversicherung AG, ERGO Direkt Lebensversicherung AG,
                    ERGO Direkt Versicherung AG, DKV Deutsche Krankenversicherung AG, ERGO Reiseversicherung AG,
                    ERGO Lebensversicherung AG, ERGO Life S.A., ERGO Beratung und Vertrieb AG`
                ]}
            />,
            container);
    }
}

export default AnnotationPortal;
