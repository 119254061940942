import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { reducer as formReducer } from 'redux-form';
import App from './App';
// next line: this is only for local development. during the build step a main.xyz.css file will be deployed, but that won't
// be copied to the docker container. instead, the 'specialized' css files from from @eg/elements will be copied
// reason for this workaround: dynamic css module imports are not supported.
import '@eg/elements/styles/styles.css';
import { Messaging } from './messaging/messaging';
import { IAppInput } from './messaging/model/input/IAppInput';
import { IGatewayClient } from './infrastructure/IGatewayClient';
import config from './config/configFactory';
import axios from 'axios';
import { createClient } from './infrastructure/gatewayClient';
import ErrorBoundary from './ErrorBoundary';
import { RepositoryType } from './messaging/model/input/RepositoryType'

// note that we are intentionally not loading polyfills here, see README.md

const rootReducer = combineReducers({
    form: formReducer
});

const store = createStore(rootReducer);


const getMountingPoint = () => {
    return document.getElementById('root-personendaten') as HTMLElement;
};

const messaging = new Messaging();

let requestingPersonIds: boolean = false;

messaging.listenToRenderPersonendaten(async (event: CustomEvent<IAppInput>) => {
    const backendPrefix = '/api'
    const input: IAppInput = { ...event.detail };
    input.repository = input.repository || RepositoryType.PDS;
    const baseCssUrl = input.apiRootUrl ? input.apiRootUrl : config.baseGatewayUrl.replace(backendPrefix, "");
    await config.loadCss(input.theme as string, baseCssUrl);
    const baseUrl = (input.apiRootUrl ? input.apiRootUrl + backendPrefix : config.baseGatewayUrl);
    const baseGatewayUrl = baseUrl + '/' + input.repository;

    const api: IGatewayClient = createClient(axios, baseGatewayUrl);

    ReactDOM.render(
        <React.StrictMode>
            <ErrorBoundary onError={input.callbacks.onError}>

                <Provider store={store}>
                    <App
                        input={input}
                        messaging={messaging}
                        api={api}
                        onBankDataFormExpand={input.onBankDataFormExpand}
                        callbacks={input.callbacks}
                        requestingPersonIDsCallback={(currentlyRequesting: boolean) => requestingPersonIds = currentlyRequesting}
                    />
                </Provider>

            </ErrorBoundary>
        </React.StrictMode>,
        getMountingPoint()
    );
});

messaging.listenToUnmountPersonendaten(() => {
    if(requestingPersonIds) {
        // Keep defering unmounting until request is finished.
        setTimeout(() => document.dispatchEvent(new CustomEvent('unmountPersonendaten')), 1000);
    }
    else {
        ReactDOM.unmountComponentAtNode(getMountingPoint());
    }
});
