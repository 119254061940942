import * as React from 'react';
import { DateInputRowWrapper } from './FormElementsWrapper';
import { Field } from 'redux-form';
import { DateValidation } from '../model/DateValidation';
import { DateInputValue } from '@eg/elements/components/DateInput/DateInput';
import { validateDatum } from '../validate';
import { mapDateInputValueToDate } from '../common/dateMapper';

interface IFuehrerscheindatumRowProps {
    validation: DateValidation;
    isLicenseDateReadonly: boolean;
}

class FuehrerscheindatumRow extends React.Component<IFuehrerscheindatumRowProps> {
    private readonly validate: (datum: DateInputValue) => string | undefined;

    constructor(props: IFuehrerscheindatumRowProps) {
        super(props);
        this.validate = (datum: DateInputValue) => {
            const errorMessage = validateDatum(datum);
            if (errorMessage) {
                return errorMessage;
            }
            return props.validation(mapDateInputValueToDate(datum) as Date);
        };
    }

    render() {
        return (
            <Field
                name="fuehrerschein.datum"
                component={DateInputRowWrapper}
                label="Führerscheindatum"
                validate={this.validate}
                disabled={this.props.isLicenseDateReadonly}
                data-component-id="fuehrerschein-datum"
            />
        );
    }
}
export default FuehrerscheindatumRow;
