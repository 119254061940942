import InquiryType from '../messaging/model/input/InquiryType';
import { IFormPolicyHolder } from '../model/IFormPolicyHolder';

export const isOneTimeEmailVisible = (disableEwe: boolean, inquiryType: InquiryType | undefined, vn: IFormPolicyHolder): boolean => {
    return disableEwe === false &&
        Boolean(inquiryType === undefined
            // obwohl im Tarif noch nicht auf "Weiter "geklickt wurde wird die Einmal-Adresse angezeigt falls sie befüllt ist
            // (Szenario ist der "Wiederaufruf" der Personendaten)
            ? vn && vn.email
            // wenn im Tarif bereits auf "Weiter" geklickt wurde wird die Einmal-Adresse angezeigt falls die EWE-Mail nicht befüllt ist
            : !vn.emailEwe);
}
