import * as React from 'react';
import FormRow from '@eg/elements/FormRow';
import { Field } from 'redux-form';
import {
    validateHausnummer,
    validateHousenumberPds,
    validateStrasse,
    validateStrassePds
} from '../validate';
import { IMeta } from './IMeta';
import { ControlWrapper } from './FormElementsWrapper';

interface IStrasseHausnummerProps {
    validateAddress?: boolean;
    isValidStreet?: boolean;
    setValidStreet?: (valid: boolean) => void;
    isValidHousenumber?: boolean;
    setValidHousenumber?: (valid: boolean) => void;
}

const StrasseHausnummerField: React.StatelessComponent<IMeta & IStrasseHausnummerProps> = (props) => {
    return (
        <FormRow label="Straße/Nr." colspans={[2, 1]}>
            <Field
                name={`${props.meta.scope}strasse`}
                component={ControlWrapper}
                validate={props.validateAddress ? validateStrassePds(props.isValidStreet) : validateStrasse}
                placeholder="Straße"
                maxLength={51}
                data-component-id={`${props.meta.role}strasse`}
                onBlur={() => {
                    if (props.validateAddress && !props.isValidStreet) {
                        if (props.setValidStreet) {
                            props.setValidStreet(true);
                        }
                    }
                }}
            />
            <Field
                name={`${props.meta.scope}hausnummer`}
                component={ControlWrapper}
                validate={props.validateAddress ? validateHousenumberPds(props.isValidHousenumber) : validateHausnummer}
                placeholder="Hausnr."
                maxLength={10}
                data-component-id={`${props.meta.role}hausnummer`}
                onBlur={() => {
                    if (props.validateAddress && !props.isValidHousenumber) {
                        if (props.setValidHousenumber) {
                            props.setValidHousenumber(true);
                        }
                    }
                }}
            />
        </FormRow>
    );
};

export default StrasseHausnummerField;
