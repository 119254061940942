const showBicAndRememberState = (hasInternationalIbanSupport: boolean, currentState: boolean, setState: (value: boolean) => void, iban: string, hasEuropeanIbanSupport: boolean): boolean => {
    if (!hasInternationalIbanSupport || hasEuropeanIbanSupport) {
        return false;
    }

    const updateBicShownState = (): boolean => {
        const value = !iban.toUpperCase().startsWith('DE');
        setState(value);
        return value;
    };

    // The below logic helps to hide the BIC field as much as possible, because most users will enter a german
    // IBAN and we wont to prevent them from seeing the BIC field even if they misstype there IBAN in the beginning
    if (iban) {
        // If the BIC is currently shown and the IBAN field has more then two characters.
        // This is to hide the BIC field as quickly as possible if the user enters a german IBAN
        if (currentState && iban.length >= 2) {
            return updateBicShownState();
        }
        // If the BIC is not currently shown and the IBAN field has at least three characters.
        // This is to show the BIC field if the user has entered three or more characters and it isn't a german IBAN
        if (!currentState && iban.length >= 3) {
            return updateBicShownState();
        }
    }

    return currentState;
};

export { showBicAndRememberState };
