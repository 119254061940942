import Provider from "@eg/elements/Provider";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { FormState, change, submit, touch } from "redux-form";
import Form, { formName } from "./Form";
import {
  getAllPartOneFieldnames,
  getBankdatenFieldNames,
  getFilledWriteableDateFields,
  getOneTimeEmailFieldName,
} from "./fieldNames";
import { IGatewayClient } from "./infrastructure/IGatewayClient";
import { IGatewayPerson } from "./infrastructure/model/IGatewayPerson";
import { IGatewaySavePersonsResponse } from "./infrastructure/model/IGatewaySavePersonsResponse";
import { IMessaging } from "./messaging/messaging";
import { IAppInput } from "./messaging/model/input/IAppInput";
import { ICallbacks } from "./messaging/model/input/ICallbacks";
import { IInputVp } from "./messaging/model/input/IInputVp";
import InquiryType from "./messaging/model/input/InquiryType";
import { RepositoryType } from "./messaging/model/input/RepositoryType";
import { mapToAppModel } from "./messaging/model/input/inputMapper";
import { ICorrectedAddress } from "./messaging/model/output/ICorrectedAddress";
import { IFinishedPersonendatenOutput } from "./messaging/model/output/IFinishedPersonendatenOutput";
import { mapPersonDataChangeOutput } from "./messaging/model/output/outputMapper";
import { mapFromGateway } from "./messaging/model/output/spcs/PersonReference";
import {
  AddressValidationType,
  IAddressValidationResults,
} from "./model/AddressValidation";
import { IFormData } from "./model/IFormData";
import { IFormPolicyHolder } from "./model/IFormPolicyHolder";
import { IStaticAppValues } from "./model/IStaticAppValues";
import { IStaticPersonalData } from "./model/IStaticPersonalData";
import { IAppState, createInitialKeweConsentState, createInitialState } from "./state/AppState";
import { getValidationResults } from "./state/IValidationResults";
import * as stateFunctions from "./state/stateFunctions";
import { InfoBoxEudsgvo } from "./subcomponents/InfoBoxEudsgvo";
import { isOneTimeEmailVisible } from "./utils/visibility";

export interface IAppProps {
  input: IAppInput;
  messaging: IMessaging;
  dispatch: Dispatch<FormState>;
  api: IGatewayClient;
  callbacks: ICallbacks;
  onBankDataFormExpand?: () => void;
  requestingPersonIDsCallback: (currentlyRequesting: boolean) => void;
}
export const ApiErrorContext = React.createContext({});
class App extends React.Component<IAppProps, IAppState> {
  private readonly initialValues: IFormData;
  private readonly staticPersonalData: IStaticPersonalData;
  private readonly staticAppValues: IStaticAppValues;
  private readonly validateAddress: boolean;

  constructor(props: IAppProps) {
    super(props);

    const mapped = mapToAppModel(this.props.input);
    this.initialValues = mapped.formData;
    this.staticPersonalData = mapped.staticPersonalData;
    this.staticAppValues = mapped.staticAppValues;
    this.validateAddress = this.props.input.addressValidation || false;
    this.setValidHousenumberVp = this.setValidHousenumberVp.bind(this);
    this.setValidStreetVp = this.setValidStreetVp.bind(this);
    this.setValidPostalCityVp = this.setValidPostalCityVp.bind(this);
    this.setValidHousenumberVn = this.setValidHousenumberVn.bind(this);
    this.setValidStreetVn = this.setValidStreetVn.bind(this);
    this.setValidPostalCityVn = this.setValidPostalCityVn.bind(this);

    const initialKeweState = createInitialKeweConsentState(
        mapped.staticPersonalData.enableNewPersonalDataFlow,
        mapped.staticPersonalData.enableKEweConsent,
        mapped.formData.vn.einverstaendnisEmailWerbung
    );

    this.state = createInitialState(mapped.personsGatewayInfo, initialKeweState);
  }

  handleNextClick = async (values: IFormData, inquiryType: InquiryType) => {
    const validationResults = getValidationResults(
      values,
      this.staticPersonalData,
      this.state.isIbanValid,
      this.props.input.vn.isIbanAlwaysVisible || false
    );

    this.setState((prevState) => ({
        ...prevState,
        keweConsent: validationResults.keweConsent,
    }));

    if (this.props.callbacks.onKeweSectionVisibilityChange) {
      this.props.callbacks.onKeweSectionVisibilityChange(validationResults.keweConsent.hideKeweContactSection)
    }

    if (
      (inquiryType === InquiryType.ANGEBOT &&
        validationResults.isAngebotMoeglich) ||
      (inquiryType === InquiryType.DIREKTABSCHLUSS &&
        validationResults.isAbschlussMoeglich)
    ) {
      try {
        this.props.api
          .savePersons(
            this.staticPersonalData.angebotsId!,
            values,
            this.state.personsGatewayInfo,
            this.staticPersonalData.enableNewPersonalDataFlow,
            this.staticPersonalData.enableKEweConsent,
            this.validateAddress
          )
          .then((response: IGatewaySavePersonsResponse) => {
            this.setState(
              (state) =>
                stateFunctions.personsGatewayInfoChanged(
                  state,
                  response.persons
                ),
              () => {
                const output: IFinishedPersonendatenOutput = {
                  isEmailOptionalValid: validationResults.isEmailOptionalValid,
                  isEmailMandatoryValid:
                    validationResults.isEmailMandatoryValid,
                };
                if (this.staticAppValues.repository === RepositoryType.SPCS) {
                  output.persons = response.persons.map(mapFromGateway);
                }
                if (!this.validateAddress) {
                  if (this.props.callbacks.onFinishedPersonendaten) {
                    this.props.callbacks.onFinishedPersonendaten(output);
                  }
                } else {
                  this.setState({ nextClickedValidateAddress: true });
                  if (
                    response &&
                    response.addressValidationResults &&
                    response.addressValidationResults.length > 0
                  ) {
                    this.handleCorrectedAddresses(
                      response.addressValidationResults
                    );
                    this.setState({
                      validationResults: response,
                    });
                    if (this.validateAddress) {
                      if (this.props.callbacks.onFinishedPersonendaten) {
                        output.isAddressCorrected = true;
                        this.props.callbacks.onFinishedPersonendaten(output);
                      }
                    }
                  } else {
                    if (this.props.callbacks.onFinishedPersonendaten) {
                      output.isAddressCorrected = false;
                      this.props.callbacks.onFinishedPersonendaten(output);
                    }
                  }
                }
                this.setState({ apiError: {} });
              }
            );
          })
          .catch((e) => {
            this.setState(
              (state) =>
                stateFunctions.personsGatewayInfoChanged(
                  state,
                  e?.response?.data?.persons
                ),
              () => {
                if (this.props.callbacks.onValidationFailed) {
                  this.props.callbacks.onValidationFailed();
                }
                if (e?.response?.data) {
                  this.setState({
                    nextClickedValidateAddress: true,
                    validationResults: e.response.data,
                    formdata: values,
                  });
                  if (e.response.data?.addressValidationResults) {
                    this.handleValidationErrors(
                      e.response.data.addressValidationResults
                    );
                    this.handleCorrectedAddresses(
                      e.response.data.addressValidationResults
                    );
                  } else {
                    this.handleUnprocessableEntity(
                      e.response.data?.message,
                      e.response.data?.reason
                    );
                  }
                } else {
                  this.handleFailure(e);
                }
              }
            );
          });
      } catch (e) {
        this.handleFailure(e as Error);
      }
    } else {
      // validation nok, user stays on personal data form

      // It is possible that fields from Part II are already displayed and must be touched
      const fieldsToBeTouched = [
        ...this.getVisibleFieldsFromPartTwo(values.vn),
      ];

      if (validationResults.isPartOneValid) {
        this.setState({ inquiryType: inquiryType });
      } else {
        // Part I touch, because not everyone is ok there
        fieldsToBeTouched.push(
          ...getAllPartOneFieldnames(
            this.props.input.vps ? this.props.input.vps.length : 0,
            this.staticPersonalData.disableEwe,
            this.staticPersonalData.vn.isGWGRequired,
            this.staticPersonalData.vn.showGeburtsort,
            this.staticPersonalData.vn.showStaatsangehoerigkeit
          )
        );
        if (this.props.input.vps) {
          (this.props.input.vps as Array<IInputVp>).forEach(
            (vp: IInputVp, index: number) => {
              if (vp.isGWGRequired) {
                fieldsToBeTouched.push(`vps[${index}].geburtsort`);
                fieldsToBeTouched.push(`vps[${index}].staatsangehoerigkeit`);
              }
            }
          );
        }
      }
      if (this.props.callbacks.onValidationFailed) {
        this.props.callbacks.onValidationFailed();
      }
      this.props.dispatch(touch(formName, ...fieldsToBeTouched));
    }
  };

  getVisibleFieldsFromPartTwo = (vn: IFormPolicyHolder) => {
    const fields = [];
    if (this.isBankdatenVisible()) {
      fields.push(...getBankdatenFieldNames());
    }
    if (
      isOneTimeEmailVisible(
        this.staticPersonalData.disableEwe,
        this.state.inquiryType,
        vn
      )
    ) {
      fields.push(...getOneTimeEmailFieldName());
    }
    return fields;
  };
  handleUnprocessableEntity(message: string, reason: string) {
    switch (reason) {
      case "person.email.email.invalid":
        this.setState({
          apiError: { message: "Bitte geben Sie eine gültige E-Mail ein." },
        });
        break;
      case "persons.are.duplicated":
        this.setState({
          apiError: {
            message: "Es werden zwei verschiedene Personen benötigt.",
          },
        });
        break;
      case "internal":
      default:
        this.setState({
          apiError: { message: "Ein interner Fehler ist aufgetreten.", reason },
        });
        this.handleFailure(new Error(message));
    }
  }
  handleValidationErrors(validationResults: IAddressValidationResults[]) {
    if (validationResults.length === 0) return;
    validationResults.forEach((result) => {
      if (result.role === "vn") {
        if (
          result.responseType === AddressValidationType.INVALID_ADDRESS &&
          result.errorStreet
        ) {
          this.setState({
            isValidStreetVn: false,
            isValidPostalCityVn: true,
            isValidHousenumberVn: true,
          });
        }
        if (
          result.responseType === AddressValidationType.INVALID_ADDRESS &&
          result.errorPostalCity
        ) {
          this.setState({
            isValidStreetVn: true,
            isValidPostalCityVn: false,
            isValidHousenumberVn: true,
          });
        }
        if (
          result.responseType === AddressValidationType.INVALID_ADDRESS &&
          result.errorHousenumber
        ) {
          this.setState({
            isValidPostalCityVn: true,
            isValidStreetVn: true,
            isValidHousenumberVn: false,
          });
        }
        if (
          result.responseType === AddressValidationType.INVALID_ADDRESS &&
          result.errorStreet &&
          result.errorPostalCity &&
          result.errorHousenumber
        ) {
          this.setState({
            isValidPostalCityVn: false,
            isValidStreetVn: false,
            isValidHousenumberVn: false,
          });
        }
      }
    });
  }

  getCorrectedFieldScope(role?: string) {
    if (role === 'vp') {
      return role+'s[0]';
    }
    return role;
  }

  handleCorrectedAddresses(validationResults: IAddressValidationResults[]) {
    if (validationResults.length === 0) return;
    const correctedAddresses: ICorrectedAddress[] = [];
    validationResults.forEach((result) => {
      if (result.responseType === AddressValidationType.ADDRESS_CORRECTED) {
        correctedAddresses.push({
          pdeId: result.personId || "",
          strasse: result.correctedStreet || "",
          hausnummer: result.correctedHousenumber || "",
          plz: result.correctedPostal || "",
          ort: result.correctedCity || "",
        });

        const scope = this.getCorrectedFieldScope(result.role);
        
        this.props.dispatch(change(formName, `${scope}.strasse`, result.correctedStreet || ""));
        this.props.dispatch(change(formName, `${scope}.hausnummer`, result.correctedHousenumber || ""));
        this.props.dispatch(change(formName, `${scope}.plz`, result.correctedPostal || ""));
        this.props.dispatch(change(formName, `${scope}.ort`, result.correctedCity || ""));
      }
    });
    if (correctedAddresses && correctedAddresses.length > 0) {
      if (this.props.callbacks.onCorrectedAddress) {
        this.props.callbacks.onCorrectedAddress(correctedAddresses);
      }
    }
  }

  setValidHousenumberVn(valid: boolean) {
    this.setState({ isValidHousenumberVn: valid });
  }

  setValidStreetVn(valid: boolean) {
    this.setState({ isValidStreetVn: valid });
  }

  setValidPostalCityVn(valid: boolean) {
    this.setState({ isValidPostalCityVn: valid });
  }

  setValidHousenumberVp(valid: boolean) {
    this.setState({ isValidHousenumberVp: valid });
  }

  setValidStreetVp(valid: boolean) {
    this.setState({ isValidStreetVp: valid });
  }

  setValidPostalCityVp(valid: boolean) {
    this.setState({ isValidPostalCityVp: valid });
  }

  componentDidUpdate(prevProps: IAppProps, prevState: IAppState) {
    if (
      prevState.inquiryType === undefined &&
      this.state.inquiryType !== undefined
    ) {
      // part II appears for the 1st time
      this.handleBankDataFormExpansion();
    }
  }

  async componentDidMount() {
    // touch all date fields in case dates have already been specified (app has already been mounted once)
    // note that this is not 100% in sync with non date fields because they fields might also
    // contain errors but are not touched explicitely.
    // Nevertheless dateinput fields have a much higher potential of confusing the user because of their complexity.
    this.props.dispatch(
      touch(formName, ...getFilledWriteableDateFields(this.props.input))
    );

    const numberOfPersonsWithoutId = this.state.personsGatewayInfo.filter(
      (item) => !item.personId
    ).length;

    if (numberOfPersonsWithoutId > 0) {
      let newPersons: IGatewayPerson[];
      try {
        this.props.requestingPersonIDsCallback(true);
        newPersons = await this.props.api.createPersons(
          this.staticPersonalData.angebotsId!,
          numberOfPersonsWithoutId
        );
      } catch (e) {
        this.handleFailure(e as Error);
        return;
      }
      this.setState(
        (state) => stateFunctions.personsGatewayInfoChanged(state, newPersons),
        () => this.props.requestingPersonIDsCallback(false)
      );
    }

    if (this.props.callbacks.onRenderComplete) {
      this.props.callbacks.onRenderComplete();
    }

    if (
      (this.props.input.vn.isGWGRequired ||
        this.props.input.vn.showStaatsangehoerigkeit) &&
      this.state.nationalities.length === 0
    ) {
      this.props.api
        .getNationalities()
        .then((nationalities) => this.setState({ nationalities }));
    }
  }

  handleChange = (values: IFormData) => {
    this.notifyPersonendatenChange(values);
  };

  handleIbanValidated = (
    values: IFormData,
    isIbanValid: boolean,
    emitEvent: boolean
  ) => {
    this.setState({ isIbanValid }, () => {
      if (emitEvent) {
        this.notifyPersonendatenChange(values);
      }
    });
  };

  notifyPersonendatenChange = (values: IFormData) => {
    const output = mapPersonDataChangeOutput(
      this.staticPersonalData,
      values,
      this.state,
      this.props
    );
    this.props.callbacks.onChangedPersonendaten(output);
  };

  handleEnterPress = () => {
    // we have to perform a "remote submit" (because we have no submit button)
    // this action will trigger the form's onSubmit function
    // see https://redux-form.com/6.6.2/examples/remotesubmit/
    this.props.dispatch(submit(formName));
  };

  handleSubmit = () => {
    // note that this only get calls if form is error-free
    if (this.props.callbacks.onSubmitPersonendaten) {
      this.props.callbacks.onSubmitPersonendaten();
    }
  };

  handleFailure = (e: Error) => {
    this.props.callbacks.onError(e);
    this.props.requestingPersonIDsCallback(false);
  };

  handleBankDataFormExpansion = () => {
    if (this.props.callbacks.onBankDataFormExpand) {
      this.props.callbacks.onBankDataFormExpand();
    } else if (this.props.onBankDataFormExpand) {
      this.props.onBankDataFormExpand();
    }
  };

  isBankdatenVisible = () => {
    return (
      this.props.input.vn.isIbanAlwaysVisible ||
      this.state.inquiryType === InquiryType.DIREKTABSCHLUSS ||
      (this.props.input.vn.iban !== undefined &&
        this.props.input.vn.iban.length > 0) ||
      (this.props.input.vn.bic !== undefined &&
        this.props.input.vn.bic.length > 0)
    );
  };

  render() {
    const disableEwe = this.props.input.disableEwe || false;
    const hinweisBezugsrechtVisible =
      this.props.input.hinweisBezugsrecht || false;

    return (
      <ApiErrorContext.Provider value={this.state.apiError}>
        <Provider theme={this.staticAppValues.theme}>
          <InfoBoxEudsgvo />
          <Form
            onChange={this.handleChange}
            onIbanValidated={this.handleIbanValidated}
            initialValues={this.initialValues}
            onSubmit={this.handleSubmit}
            onEnterPress={this.handleEnterPress}
            inquiryType={this.state.inquiryType}
            staticPersonalData={this.staticPersonalData}
            staticText={this.staticAppValues.text}
            isBankdatenVisible={this.isBankdatenVisible()}
            hasInternationalIbanSupport={
              this.props.input.vn.hasInternationalIbanSupport
            }
            hasEuropeanIbanSupport={this.props.input.vn.hasEuropeanIbanSupport}
            isVnCityReadonly={this.props.input.vn.isCityAndPostCodeReadonly}
            messaging={this.props.messaging}
            onNextClick={this.handleNextClick}
            disableEwe={disableEwe}
            hinweisBezugsrecht={hinweisBezugsrechtVisible}
            nationalities={this.state.nationalities}
            validateAddress={this.state.nextClickedValidateAddress}
            isValidStreetVn={this.state.isValidStreetVn}
            setValidStreetVn={this.setValidStreetVn}
            isValidHousenumberVn={this.state.isValidHousenumberVn}
            setValidHousenumberVn={this.setValidHousenumberVn}
            isValidPostalCityVn={this.state.isValidPostalCityVn}
            setValidPostalCityVn={this.setValidPostalCityVn}
            validationResults={this.state.validationResults}
            callbacks={this.props.callbacks}
            api={this.props.api}
            personsGatewayInfo={this.state.personsGatewayInfo}
            keweConsentValidation={this.state.keweConsent}
          />
        </Provider>
      </ApiErrorContext.Provider>
    );
  }
}

export default connect()(App);
