import { IFormData } from './model/IFormData';

const cloneDeep = require('lodash.clonedeep');

const sanitizeValues = (values: IFormData): IFormData => {
    const result: IFormData = cloneDeep(values);

    const trimStrings = (obj: any) => {
        for (const k in obj) {
            if (obj.hasOwnProperty(k)) {
                if (typeof obj[k] === 'object' && obj[k] !== null) {
                    trimStrings(obj[k]);
                }
                if (typeof obj[k] === 'string') {
                    obj[k] = obj[k].trim();
                }
            }
        }
    };

    trimStrings(result);

    result.vn.iban = result.vn.iban.toUpperCase();

    return result;
};

export { sanitizeValues };
