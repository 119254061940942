import * as React from 'react';
import { Field } from 'redux-form';
import { validateName } from '../validate';
import { InputRowWrapper } from './FormElementsWrapper';
import { IMeta } from './IMeta';

const NachnameField = (props: IMeta) => (
    <Field
        name={`${props.meta.scope}nachname`}
        component={InputRowWrapper}
        label="Nachname"
        placeholder="Nachname"
        validate={validateName}
        maxLength={36}
        data-component-id={`${props.meta.role}nachname`}
    />
);

export default NachnameField;
