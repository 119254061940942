import * as React from 'react';
import { InputWithHintWrapper } from './FormElementsWrapper';
import FormRow from '@eg/elements/FormRow';
import { Field } from 'redux-form';
import { validateVorwahl, validateRufnummer } from '../validate';

interface ITelefonFieldProps {
    label: JSX.Element | string;
}

const TelefonField: React.StatelessComponent<ITelefonFieldProps> = (props: ITelefonFieldProps) => (
    <FormRow label={props.label}>
        <Field
            name="vorwahl"
            component={InputWithHintWrapper}
            placeholder="Vorwahl"
            validate={validateVorwahl}
            maxLength={6}
            data-component-id="tel-vorwahl"
        />
        <Field
            name="rufnummer"
            component={InputWithHintWrapper}
            placeholder="Rufnummer"
            validate={validateRufnummer}
            maxLength={12}
            data-component-id="tel-rufnummer"
        />
    </FormRow>
);

export default TelefonField;
