import * as React from 'react';
import { Field } from 'redux-form';
import { validateOrt } from '../validate';
import { InputRowWrapper } from './FormElementsWrapper';
import { IMeta } from './IMeta';

const GeburtsortField = (props: IMeta) => {
    return (
    <Field
        name={`${props.meta.scope}geburtsort`}
        component={InputRowWrapper}
        placeholder="Geburtsort"
        label="Geburtsort"
        validate={validateOrt}
        maxLength={36}
        data-component-id={`${props.meta.role}geburtsort`}
    />);

    };

export default GeburtsortField;
