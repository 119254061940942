import * as React from 'react';

const InfoIbanNoSepaTop = () => {

    return (
        <>
            <h3 style={{ margin: '1em 0', fontSize: '16px', fontWeight: 'bold'}}>Ihre Bankverbindung</h3>
            <p>
                Für das Zustandekommen eines Vertrags benötigen wir Ihre Bankverbindung.
            </p>
        </>
    );
};

export default InfoIbanNoSepaTop;
