import * as React from 'react';
import { Field } from 'redux-form';
import { IMeta } from './IMeta';
import { SelectRow } from '@eg/elements/components/SelectRow';
import { GatewayPersonTitle } from '../infrastructure/model/GatewayPersonTitle'

// tslint:disable-next-line:no-any
const renderTitelField = ({ input, label, name }: any) => {
    // tslint:disable-next-line:no-any
    const chg = (e: React.ChangeEvent<HTMLInputElement>) => input.onChange(e.target.value);

    return (
        <SelectRow
            label={label}
            defaultValue={input.value}
            onChange={chg}
            name={name}
        >
            {
                Object.values(GatewayPersonTitle).map(title =>
                    <option value={title}>{title}</option>
                )
            }
        </SelectRow>
    );
};

const TitelField: React.StatelessComponent<IMeta> = (props: IMeta) => {
    return (
        <Field
            name={`${props.meta.scope}titel`}
            component={renderTitelField}
            label="Titel"
            data-component-id={`${props.meta.scope}titel`}
        />
    );
};

export default TitelField;
