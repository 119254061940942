import { DateInputValue } from '@eg/elements/components/DateInput';
import { memoize } from 'lodash';
import { mapDateInputValueToDate } from './common/dateMapper';
import { IGatewaySavePersonsResponse } from './infrastructure/model/IGatewaySavePersonsResponse';
import { DateValidation } from './model/DateValidation';
import Gender from './model/Gender';
import { IDriverLicense } from './model/IDriverLicense';
import { IFormData } from './model/IFormData';
import { IFormInsuredPerson } from './model/IFormInsuredPerson';
import { IFormPolicyHolder } from './model/IFormPolicyHolder';
import { IPersonGatewayInfo } from './model/IPersonGatewayInfo';
import { IStaticPersonalData } from './model/IStaticPersonalData';
import { IKeweConsentValidation } from './model/IKeweConsentValidation';

export const validateAnrede = (geschlecht?: Gender) => {
    if (geschlecht === undefined) {
        return 'Bitte wählen Sie eine Anrede aus.';
    }
    return undefined;
};

const validateRadioButtonDefault = (radioButtonValue: boolean | undefined, expectedDefaultValue: boolean): string | undefined => {
    if (expectedDefaultValue !== radioButtonValue && `${expectedDefaultValue}` !== `${radioButtonValue}`) {
        return 'Online können wir Ihnen diese Versicherung leider nicht anbieten. ' +
            'Bitte wenden Sie sich an einen ERGO Vertriebspartner in Ihrer Nähe.';
    }
    return undefined;
};

export const validateRadioButtonDefaultTrue = (radioButtonValue: boolean | undefined): string | undefined => {
    return validateRadioButtonDefault(radioButtonValue, true);
};

export const validateRadioButtonDefaultFalse = (radioButtonValue: boolean | undefined): string | undefined => {
    return validateRadioButtonDefault(radioButtonValue, false);
};

export const validateRadioButtonGwg = (radioButtonValue?: boolean): string | undefined => {
    if (radioButtonValue === undefined) {
        return 'Bitte machen Sie eine Angabe.';
    }
    return validateRadioButtonDefaultTrue(radioButtonValue);
};

export const validatePlz = (plz: string): string | undefined => {
    const onlyNumbers = /^\d+$/;

    if (plz === undefined || (plz.length === 5 && onlyNumbers.test(plz))) {
        return undefined;
    }
    return 'Bitte geben Sie eine gültige Postleitzahl ein.';
};

export const validatePlzPds = memoize((isPlzInPdsValid) => (plz: string) => {
    const onlyNumbers = /^\d+$/;
    if (plz.length === 5 && onlyNumbers.test(plz) && isPlzInPdsValid !== false) {
        return undefined;
    } else {
        return 'Bitte geben Sie eine gültige Postleitzahl ein.';
    }
});

export const validateName = (name: string): string | undefined => {
    const onlyLetters = /^[a-zA-Z\u00c0-\u01ff -.]+$/;
    if (onlyLetters.test(name)) {
        return undefined;
    }
    return 'Bitte geben Sie einen gültigen Namen ein.';
};

export const validateOrt = (ort: string): string | undefined => {
    const address = /^[0-9A-Za-zäüöÄÜÖ\u00c0-\u01ff ./)(-]+$/;
    if (address.test(ort)) {
        return undefined;
    }
    return 'Bitte geben Sie einen gültigen Ort ein.';
};

export const validateOrtPds = memoize((isOrtInPdsValid) => (ort: string) => {
    const address = /^[0-9A-Za-zäüöÄÜÖ\u00c0-\u01ff ./)(-]+$/;
    if (address.test(ort) && isOrtInPdsValid !== false) {
        return undefined;
    } else {
        return 'Bitte geben Sie einen gültigen Ort ein.';
    }
});

export const validateNationality = (nationality?: string): string | undefined => {
    if (nationality) {
        return undefined;
    }
    return 'Bitte wählen Sie eine Staatsangehörigkeit aus.';
};

const emailMessage = 'Bitte geben Sie Ihre E-Mail-Adresse an.';
const incorrectEmailMessage = 'Bitte geben Sie eine gültige E-Mail ein.'

export const validateEmailAsOptional = (email: string): string | undefined => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.length === 0 || pattern.test(email)) {
        return undefined;
    }
    return email.length > 0 ? incorrectEmailMessage : emailMessage;
};

export const validateEmailAsMandatory = (email: string): string | undefined => {
    if (email.length > 0 && validateEmailAsOptional(email) === undefined) {
        return undefined;
    }

    return email.length === 0 ? emailMessage : incorrectEmailMessage;
};

export const validateHausnummer = (hausnummer: string) => {
    const housenumber = /^\d+[a-zA-Z\d _.\-/]*$/;
    if (housenumber.test(hausnummer)) {
        return undefined;
    }
    return 'Bitte geben Sie eine gültige Hausnummer ein.';
};

export const validateHousenumberPds = memoize((isHousenumberInPdsValid) => (housenumber: string) => {
    const housenumberRegex = /^\d+[a-zA-Z\d _.\-/]*$/;
    if (housenumberRegex.test(housenumber) && isHousenumberInPdsValid !== false) {
        return undefined;
    } else {
        return 'Bitte geben Sie eine gültige Hausnummer ein.';
    }
});

export const validateStrasse = (strasse: string) => {
    const street = /^[0-9A-Za-z\u00c0-\u01ff'. -]+$/;
    if (street.test(strasse)) {
        return undefined;
    }
    return 'Bitte geben Sie eine gültige Strasse ein.';
};

export const validateStrassePds = memoize((isStreetInPdsValid) => (strasse: string) => {
    const street = /^[0-9A-Za-z\u00c0-\u01ff'. -]+$/;
    if (street.test(strasse) && isStreetInPdsValid !== false) {
        return undefined;
    } else {
        return 'Bitte geben Sie eine gültige Strasse ein.';
    }
});

export const validateVorwahl = (vorwahl: string, {vn: {rufnummer}}: IFormData) => {
    const onlyNumbers = /^\d+$/;

    if (vorwahl && !vorwahl.startsWith('0')) {
        return 'Bitte geben Sie die Vorwahl mit führender "0" ein.';
    }

    if (vorwahl && vorwahl.length < 2) {
        return 'Bitte geben Sie eine gültige Vorwahl ein.';
    }

    if (!vorwahl && !rufnummer) {
        return undefined;
    }

    // Since fields vorwahl & rufnummer are interdependent, an empty string is returned to avoid too much warnings in GUI,
    // when vorwahl is filled initially but rufnummer is empty.
    if (onlyNumbers.test(vorwahl) && !rufnummer) {
        return '';
    }

    if (!vorwahl || onlyNumbers.test(vorwahl)) {
        return undefined;
    }

    return 'Bitte geben Sie eine gültige Vorwahl ein.';
};

export const validateRufnummer = (rufnummer: string, {vn: {vorwahl}}: IFormData) => {
    const onlyNumbers = /^\d+$/;

    if (!rufnummer && !vorwahl) {
        return undefined;
    }

    if (onlyNumbers.test(rufnummer) && vorwahl) {
        return undefined;
    }

    if ((onlyNumbers.test(rufnummer)) && !vorwahl) {
        return 'Bitte geben Sie Vorwahl und Rufnummer getrennt ein.';
    }

    return 'Bitte geben Sie eine gültige Rufnummer ein.';
};

export const validateDatum = (datum: DateInputValue) => {
    if (!datum || !datum.year || datum.year.length !== 4 || !datum.month || datum.month.length > 2 || !datum.day || datum.day.length > 2) {
        return 'Bitte geben Sie ein gültiges Datum an.';
    }
    return undefined;
};

const isVnValid = (vn: IFormPolicyHolder, fuehrerscheinDatumValidation?: DateValidation, staticPersonalData?: IStaticPersonalData) => {
    let vnValid: boolean = validateAnrede(vn.geschlecht) === undefined
        && validateName(vn.nachname) === undefined
        && validateName(vn.vorname) === undefined
        && validateStrasse(vn.strasse) === undefined
        && validateHausnummer(vn.hausnummer) === undefined
        && validatePlz(vn.plz) === undefined
        && validateOrt(vn.ort) === undefined
        && validateVorwahl(vn.vorwahl, { vn, vps: [] }) === undefined
        && validateRufnummer(vn.rufnummer, { vn, vps: [] }) === undefined
        && (fuehrerscheinDatumValidation === undefined
            || fuehrerscheinDatumValidation(mapDateInputValueToDate((vn.fuehrerschein as IDriverLicense).datum) as Date) === undefined);

    if (vn.gwgoptions && vn.gwgoptions.gwgRequired) {
        vnValid = vnValid && validateNationality(vn.staatsangehoerigkeit) === undefined
            && validateOrt(vn.geburtsort) === undefined
            && validateRadioButtonGwg(vn.gwgoptions.wirtschaftlicheigentverantwortlich) === undefined;
    }

    if(staticPersonalData){
        if(staticPersonalData.vn.showGeburtsort){
            vnValid = vnValid  && validateOrt(vn.geburtsort) === undefined;
        }

        if(staticPersonalData.vn.showStaatsangehoerigkeit){
            vnValid = vnValid  && validateNationality(vn.staatsangehoerigkeit) === undefined;
        }
    }
    return vnValid;

};

const isVpValid = (vp: IFormInsuredPerson, geburtsdatumValidation?: DateValidation) => {

    if (validateAnrede(vp.geschlecht) !== undefined || validateName(vp.nachname) !== undefined || validateName(vp.vorname) !== undefined) {
        return false;
    }

    if (vp.hasDifferentAddress) {
        if (validateStrasse(vp.strasse) !== undefined
            || validateHausnummer(vp.hausnummer) !== undefined
            || validatePlz(vp.plz) !== undefined
            || validateOrt(vp.ort) !== undefined) {

            return false;
        }
    }

    if (geburtsdatumValidation) {
        if (geburtsdatumValidation(mapDateInputValueToDate(vp.geburtsdatum) as Date) !== undefined) {
            return false;
        }
    }

    if (vp.gwgoptions && vp.gwgoptions.gwgRequired) {
        if (validateNationality(vp.staatsangehoerigkeit) !== undefined) {
            return false;
        }
        if(validateOrt(vp.geburtsort) !== undefined) {
            return false;
        }
    }

    return true;
};

export type validationFx = (values: IFormData,
                            isIbanValid: boolean,
                            ibanAlwaysVisible: boolean,
                            geburtsdatumValidations: DateValidation[],
                            isEMailOptional?: boolean,
                            disableEwe?: boolean,
                            fuehrerscheinDatumValidation?: DateValidation,
                            staticPersonalData?: IStaticPersonalData) => boolean;

export const isPartOneValid: validationFx = (formData, isIbanValid, ibanAlwaysVisible, geburtsdatumValidations, isEMailOptional, disableEwe, fuehrerscheinDatumValidation,
                                             staticPersonalData) => {

    if (ibanAlwaysVisible && !isIbanValid) {
        return false;
    }

    if (!isVnValid(formData.vn, fuehrerscheinDatumValidation, staticPersonalData)) {
        return false;
    }

    let result = true;
    formData.vps.forEach((vp: IFormInsuredPerson, index: number) => {
        if (!isVpValid(vp, geburtsdatumValidations[index])) {
            result = false;
        }
    });
    return result;
};

export const isEMailValid = (values: IFormData, isEMailOptional = false): boolean => {
    return isEMailOptional
        ? (validateEmailAsOptional(values.vn.emailEwe) === undefined && validateEmailAsOptional(values.vn.email) === undefined)
        : (validateEmailAsMandatory(values.vn.emailEwe) === undefined || validateEmailAsMandatory(values.vn.email) === undefined);
};

export const isAngebotMoeglich: validationFx = (values, isIbanValid, ibanAlwaysVisible, geburtsdatumValidations, isEMailOptional = false, disableEwe, fuehrerscheinDatumValidation,
                                                staticPersonalData) => {
    const eMailValid = isEMailValid(values, isEMailOptional);

    const isGwgRadioButtonsValid = values.vn.gwgoptions && values.vn.gwgoptions.gwgRequired
        ? validateRadioButtonDefaultTrue(values.vn.gwgoptions.wirtschaftlicheigentverantwortlich) === undefined
        : true;

    return isPartOneValid(values, isIbanValid, ibanAlwaysVisible, geburtsdatumValidations, isEMailOptional, disableEwe, fuehrerscheinDatumValidation, staticPersonalData)
        && eMailValid && isGwgRadioButtonsValid;
};

export const isAbschlussMoeglich: validationFx = (values, isIbanValid, ibanAlwaysVisible, geburtsdatumValidations, isEMailOptional, disableEwe, fuehrerscheinDatumValidation,
                                                  staticPersonalData) => {
    return isAngebotMoeglich(values, isIbanValid, ibanAlwaysVisible, geburtsdatumValidations, isEMailOptional, disableEwe, fuehrerscheinDatumValidation, staticPersonalData)
        && isIbanValid && isEMailValid(values);
};

export const isPromotionSuccessful: validationFx = () => {
    return false;
};

export const getVpsValidationFlags = (index: number, personsGatewayInfo: IPersonGatewayInfo[] | undefined, validationResults:IGatewaySavePersonsResponse | undefined) => {
    const getVpsPersonId = (index: number) =>
      personsGatewayInfo
        ? personsGatewayInfo[index + 1].personId
        : "";
  
    const personId = getVpsPersonId(index);
    const vpResults =
      validationResults?.addressValidationResults?.filter(
        (vp) =>
          vp.personId === personId && vp.responseType === "ADRESSE_UNGUELTIG"
      );
    const validationFlags = {
      isValidStreetVp: true,
      isValidHousenumberVp: true,
      isValidPostalCityVp: true,
    };
    if (vpResults) {
      vpResults.forEach((result) => {
        if (index != null) {
          validationFlags.isValidStreetVp = !result.errorStreet;
          validationFlags.isValidHousenumberVp = !result.errorHousenumber;
          validationFlags.isValidPostalCityVp = !result.errorPostalCity;
        }
      });
    }
    return validationFlags;
  };

const isKeweEmailSet = (values: IFormData): boolean => 
    Boolean(values.vn.emailEwe.length);

const isKewePhoneSet = (values: IFormData): boolean => 
    Boolean(values.vn.rufnummer.length);


export const isKeweConsentSet = (values: IFormData, ibanAlwaysVisible: boolean, isIbanValid: boolean): IKeweConsentValidation => {
    const isPersonDataInvalid = !isVnValid(values.vn) || (ibanAlwaysVisible && !isIbanValid);

    if (isPersonDataInvalid) {
        return {
            hideKeweContactSection: false,
            hideKeweEmailField: false,
        };
    }

    return {
        hideKeweContactSection: !isKewePhoneSet(values) && !isKeweEmailSet(values),
        hideKeweEmailField: !isKeweEmailSet(values),
    }
}
