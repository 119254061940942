import * as React from 'react';
import MessageBox from '@eg/elements/MessageBox';

const InfoBoxEudsgvo = () => {
    return (
        <MessageBox
            data-test-id="info_eudsgvo"
            children={
            <div>
                Bei der Verarbeitung von personenbezogenen Daten beachtet ERGO die Vorschriften der
                EU-Datenschutz-Grundverordnung. Ausführliche Informationen finden Sie in unserem Datenschutz-Bereich.
                Bei Anforderung eines Angebots erhalten Sie die Informationen in Ihren Vertragsunterlagen. Ihre Daten
                speichern wir und der Versicherer für mindestens 30 Tage, bei einer Angebotserstellung für 6 Monate.
                Der Vorteil für Sie: Wir können Sie besser betreuen, wenn Fragen auftreten.
            </div>
            }
        />
    );
};

export { InfoBoxEudsgvo };
