import { getVpCityFieldnames, getVpStreetFieldnames } from './fieldNames';

const createCleanAddress = (vpIndex: number,
                            hasDifferentAddress: boolean,
                            plz: string,
                            ort: string,
                            isWohnortReadOnly: boolean,
                            // tslint:disable-next-line:no-any
                            change: (field: string, value: any) => void,
                            untouch: (...field: string[]) => void): () => void => {

    if (!isWohnortReadOnly) {
        return () => {
            getVpStreetFieldnames(vpIndex).concat(getVpCityFieldnames(vpIndex)).forEach(item => {
                change(item, '');
                untouch(item);
            });
         };
    }

    return hasDifferentAddress
        ? () => {
            // copy location from the VP into the VN
            change('vn.plz', plz);
            change('vn.ort', ort);

            getVpStreetFieldnames(vpIndex).forEach(item => {
                change(item, '');
                untouch(item);
            });
        }
        : () => {
            // copy location from the VN into the VP
            change(`vps[${vpIndex}].plz`, plz);
            change(`vps[${vpIndex}].ort`, ort);
        };
};

export { createCleanAddress };
