import * as React from 'react';
import TelefonField from './TelefonField';
import OnetimeEmailField from './OnetimeEmailField';

export interface IKontaktdatenProps {
    isEmailOptional: boolean;
    showEmailConfirmationTooltip: boolean;
}

const Kontaktdaten: React.StatelessComponent<IKontaktdatenProps> = (props: IKontaktdatenProps) => {
    return (
        <>
            <OnetimeEmailField meta={{scope: '', role: ''}}
                               isOptional={props.isEmailOptional}
                               showEmailConfirmationTooltip={props.showEmailConfirmationTooltip}
            />
            <TelefonField label="Telefon"/>
        </>
    );
};

export default Kontaktdaten;
