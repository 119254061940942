import * as React from 'react';
import { InputRowWrapper } from './FormElementsWrapper';
import { Field } from 'redux-form';

const tooltiptext = 'Ihre BIC finden Sie auf Ihrer Bankkarte oder auf Ihrem Kontoauszug.';

const BicField = () => (
    <Field
        name="vn.bic"
        component={InputRowWrapper}
        label="BIC"
        placeholder="BIC"
        {...{tooltiptext}}
        maxLength={11}
        data-component-id="bic"
    />
);

export default BicField;
