import { IFormData } from "../model/IFormData";

export enum KeweConsentResult {
  EMAIL_CONSENT_PROVIDED = "EMAIL_CONSENT_PROVIDED",
  PHONE_CONSENT_PROVIDED = "PHONE_CONSENT_PROVIDED",
  BOTH_EMAIL_PHONE_CONSENT_PROVIDED = "BOTH_EMAIL_PHONE_CONSENT_PROVIDED",
  NO_EMAIL_PHONE_CONSENT_PROVIDED = "NO_EMAIL_PHONE_CONSENT_PROVIDED",
  KEWE_CONSENT_DISABLED = "KEWE_CONSENT_DISABLED",
}

export enum OfferEngineKeweConsentResult {
  CONSENT_PROVIDED = "JA",
  CONSENT_NOT_PROVIDED = "WIDERSPROCHEN",
  KEWE_CONSENT_DISABLED = "NEIN",
}

export const getKeweConsentResult = (
  isNewFlowEnabled: boolean,
  isKeweEnabled: boolean,
  values: IFormData
) => {
  if (!isNewFlowEnabled) {
    return undefined;
  }

  if (!isKeweEnabled) {
    return KeweConsentResult.KEWE_CONSENT_DISABLED;
  }

  const email = values.vn.emailEwe;
  const phoneNumber = values.vn.vorwahl && values.vn.rufnummer;

  if (!email && !phoneNumber) {
    return KeweConsentResult.NO_EMAIL_PHONE_CONSENT_PROVIDED;
  }

  if (email && phoneNumber) {
    return KeweConsentResult.BOTH_EMAIL_PHONE_CONSENT_PROVIDED;
  }

  if (email) {
    return KeweConsentResult.EMAIL_CONSENT_PROVIDED;
  }

  if (phoneNumber) {
    return KeweConsentResult.PHONE_CONSENT_PROVIDED;
  }
};

export const mapKeweConsent = (keweConsentResult?: KeweConsentResult) => {
  switch (keweConsentResult) {
    case KeweConsentResult.EMAIL_CONSENT_PROVIDED:
      return {
        emailStatus: OfferEngineKeweConsentResult.CONSENT_PROVIDED,
        phoneStatus: OfferEngineKeweConsentResult.CONSENT_NOT_PROVIDED,
      };
    case KeweConsentResult.PHONE_CONSENT_PROVIDED:
      return {
        emailStatus: OfferEngineKeweConsentResult.CONSENT_NOT_PROVIDED,
        phoneStatus: OfferEngineKeweConsentResult.CONSENT_PROVIDED,
      };
    case KeweConsentResult.BOTH_EMAIL_PHONE_CONSENT_PROVIDED:
      return {
        emailStatus: OfferEngineKeweConsentResult.CONSENT_PROVIDED,
        phoneStatus: OfferEngineKeweConsentResult.CONSENT_PROVIDED,
      };
    case KeweConsentResult.NO_EMAIL_PHONE_CONSENT_PROVIDED:
      return {
        emailStatus: OfferEngineKeweConsentResult.CONSENT_NOT_PROVIDED,
        phoneStatus: OfferEngineKeweConsentResult.CONSENT_NOT_PROVIDED,
      };
    case KeweConsentResult.KEWE_CONSENT_DISABLED:
    default:
      return {
        emailStatus: OfferEngineKeweConsentResult.KEWE_CONSENT_DISABLED,
        phoneStatus: OfferEngineKeweConsentResult.KEWE_CONSENT_DISABLED,
      };
  }
};
