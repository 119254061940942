import * as React from 'react';
import IbanField from '../subcomponents/IbanField';
import InfoIbanBelow from '../subcomponents/InfoIbanBelow';
import InfoIbanTop from '../subcomponents/InfoIbanTop';
import IbanZusatzdatenField from '../subcomponents/IbanZusatzdatenField';
import AbbuchungsTageField from '../subcomponents/AbbuchungsTageField';
import { ITextStatic } from '../model/IStaticAppValues';
import BicField from '../subcomponents/BicField';
import InfoIbanNoSepaTop from '../subcomponents/InfoIbanNoSepaTop';

interface IBankdatenSection {
    fullName: string;
    datum: string;
    abbuchungsTage?: number[];
    text: ITextStatic;
    showBic?: boolean;
    showSepaBankInfo: boolean;
}

const BankdatenSection: React.StatelessComponent<IBankdatenSection> = (props) => (
    <>
        <hr />
        {props.showSepaBankInfo &&
        <InfoIbanTop text={props.text}/>
        }
        {!props.showSepaBankInfo &&
        <InfoIbanNoSepaTop/>
        }
        <br />
        <IbanField />
        {props.showBic &&
            <BicField />
        }
        {props.abbuchungsTage &&
            props.abbuchungsTage.length > 0 &&
            props.showSepaBankInfo &&
            <AbbuchungsTageField abbuchungsTage={props.abbuchungsTage}/>
        }
        <IbanZusatzdatenField fullName={props.fullName} datum={props.datum} showDatum={props.showSepaBankInfo}/>
        {props.showSepaBankInfo &&
        <InfoIbanBelow />
        }
    </>
);

export default BankdatenSection;
