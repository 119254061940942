import * as React from 'react';

const BezugsrechtHinweisSection: React.StatelessComponent = props => (
    <div >
        <hr style={{marginTop: '25px', marginBottom: '25px'}}/>
        <div className="esc_box esc_box--around" data-component-id="hinweis-bezugsrecht">
            <p>Das <b>Bezugsrecht</b> haben automatisch die Erben der versicherten Person nach der gesetzlichen Erbfolge.
                Also Ihre Erben, wenn Sie selbst Versicherungsnehmer und versicherte Person sind.
            </p>
            <p>
                Jemand anders soll die Versicherungssumme bekommen? Dann ändern Sie später einfach den Bezugsberechtigten.
            </p>
        </div>
        <hr style={{marginTop: '25px', marginBottom: '25px'}}/>
    </div>
);

export default BezugsrechtHinweisSection;
