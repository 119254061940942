export enum AddressValidationType {
    INVALID_ADDRESS = 'ADRESSE_UNGUELTIG',
    ADDRESS_CORRECTED = 'ADRESSE_KORRIGIERT'
}

export interface IAddressValidationResults {
    personId?: string;
    responseType?: AddressValidationType;
    errorStreet?: boolean;
    errorHousenumber?: boolean;
    errorPostalCity?: boolean;
    correctedStreet?: string;
    correctedHousenumber?: string;
    correctedPostal?: string;
    correctedCity?: string;
    role?: string;
}
