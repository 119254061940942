import * as React from 'react';
import { ReactNode } from "react";
import { FormSection } from 'redux-form';
import AdressSection from '../sections/AddressSection';
import FuehrerscheinSection from '../sections/FuehrerscheinSection';
import PersonSection from '../sections/PersonSection';
import Headline from './Headline';
import KEweConsentSection from "./KEwe/KEweConsentSection";
import { IVnCommonProps } from "./Vn";
import EmailField from './EmailField';
import { IKeweConsentValidation } from '../model/IKeweConsentValidation';

export interface IVnKEweConsentProps extends IVnCommonProps {
    bankDataComponent?: ReactNode;
    isKeweConsentEnabled: boolean;
    hasVps: boolean;
    keweConsentValidation: IKeweConsentValidation;
    vnAddressCorrected?: boolean;
}

const VnKEweConsent: React.FC<IVnKEweConsentProps> = (props: IVnKEweConsentProps) => {
    const metaProps = {scope: '', role: ''};

    return (
        <>
            <FormSection name="vn">
                <Headline text={props.staticData.ueberschrift}/>
                {/* next 2 lines: the scope does not have to be explicitely passed, it is defined by the name of the form section
                                    (in contrast to how it works for the VP FieldArray where the scope has to be explicitely defined) */}
                <PersonSection
                    meta={metaProps}
                    isGeburtsdatumWriteable={false}
                    isSexReadOnly={props.staticData.isSexReadOnly}
                    isGWGRequired={props.staticData.isGWGRequired}
                    showStaatsangehoerigkeit={props.staticData.showStaatsangehoerigkeit}
                    showGeburtsort={props.staticData.showGeburtsort}
                    touch={props.touch}
                    nationalities={props.nationalities}
                    showTitel={props.showTitel}
                />
                <AdressSection
                    meta={metaProps}
                    isWohnortReadOnly={props.dynamicData.isWohnortReadOnly}
                    isGWGRequired={props.staticData.isGWGRequired}
                    validateAddress={props.validateAddress}
                    isValidStreetVn={props.isValidStreetVn}
                    setValidStreetVn={props.setValidStreetVn}
                    isValidHousenumberVn={props.isValidHousenumberVn}
                    setValidHousenumberVn={props.setValidHousenumberVn}
                    isValidPostalCityVn={props.isValidPostalCityVn}
                    setValidPostalCityVn={props.setValidPostalCityVn}
                    touch={props.touch}
                    role='vn'
                    isNewPersonalDataFlow
                    isVnAddressCorrected={props.vnAddressCorrected}
                />
            </FormSection>

            {/* Have to be separated from FormSection because BankDataSection has vn.* prefixes already */}
            { props.bankDataComponent }

            <FormSection name="vn">
                {props.isKeweConsentEnabled ? <KEweConsentSection hasVps={props.hasVps} {...props.keweConsentValidation} /> : <EmailField />}

                {props.staticData.fuehrerschein &&
                    <FuehrerscheinSection
                        datumValidation={props.staticData.fuehrerschein.datumValidation}
                        touch={props.touch}
                        isLicenseDateReadonly={props.staticData.fuehrerschein.isLicenseDateReadonly}
                    />
                }
            </FormSection>
        </>
    )
};

export default VnKEweConsent;
