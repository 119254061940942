import { DateInputValue } from '@eg/elements/components/DateInput';

export const mapDateToDateInputValue = (date: Date): DateInputValue => {
    return {
        year: date.getFullYear().toString(),
        month: ('0' + (date.getMonth() + 1).toString()).slice(-2),
        day: ('0' + date.getDate().toString()).slice(-2)
    };
};

const padZero = (n: string, width: number): string => {
    width -= n.length;
    if (width > 0) {
        return new Array(width + (/\./.test(n) ? 2 : 1)).join('0') + n;
    }
    return n;
};

export const mapDateToGermanString = (date: Date): string => {
    return `${('0' + date.getDate().toString()).slice(-2)}.${('0' + (date.getMonth() + 1).toString()).slice(-2)}.${date.getFullYear().toString()}`;
};

export const mapDateInputValueToDate = (datum: DateInputValue): Date | undefined => {
    const d = new Date(`${padZero(datum.year as string, 4)}-${padZero(datum.month as string, 2)}-${padZero(datum.day as string, 2)}T00:00:00Z`);

    // tslint:disable-next-line:no-any
    if (d instanceof Date && !isNaN(d as any)) {
        return d;
    }

    return undefined;
};

export const mapDateInputValueToString = (input: DateInputValue): string => {
    return `${input.year}-${padZero(input.month as string, 2)}-${padZero(input.day as string, 2)}`;
};
