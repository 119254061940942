import * as React from 'react';
import Modal from '@eg/elements/Modal';
import Button from '@eg/elements/Button';
import { ArrowRightIcon } from '@eg/elements/components/Icons';
import GwgRadioGroup from '../subcomponents/GwgRadioGroup';

interface IGwgInformationSectionProps {
    touch: (...field: string[]) => void;
    onGWGBeratungsdialogDismissed?: () => void;
    onGWGBeratungsdialogForward?: () => void;
}

interface IGwgInformationSectionState {
    showBeratungsDialog: boolean;
    beratungsDialogText?: string;
}

export default class GwgInformationSection extends React.Component<IGwgInformationSectionProps, IGwgInformationSectionState> {

    constructor(props: IGwgInformationSectionProps) {
        super(props);
        this.state = {
            showBeratungsDialog: false,
        };

    }

    render() {

        return (
            <>
                <hr/>
                <h3
                    style={{margin: '1em 0', fontSize: '16px', fontWeight: 'bold'}}
                    data-component-id="gwg-auskunft-header"
                >
                    Auskunft nach dem Geldwäschegesetz
                </h3>

                <GwgRadioGroup
                    groupId="gwgoptions.wirtschaftlicheigentverantwortlich"
                    label="Die Aufnahme der Geschäftsbeziehungen und/oder die damit verbundenen Geld-Transaktionen erfolgen auf eigene Veranlassung?"
                    tooltip={<><span style={{fontWeight: 'bold'}}>Wirtschaftlich Berechtigter</span> ist jede natürliche Person, in deren
                        Eigentum oder unter deren Kontrolle der Vertragspartner letztlich steht. Aber auch die natürliche Person
                        auf deren Veranlassung eine Transaktion durchgeführt oder eine Geschäftsbeziehung begründet wird. Dazu
                        zählen z. B.: abweichender Beitragszahler, Vereinbarung von Rechten Dritter (z. B. unwiderrufliches
                        Bezugsrecht, Abtretung oder Verpfändung).</>}
                    defaultValue={undefined}
                    callbackNein={() => this.showBeratungsDialog(`Wenn Sie die die Versicherung auf Veranlassung einer anderen
                    Person abschließen möchten, wenden Sie sich bitte an einen ERGO Vertriebspartner in Ihrer Nähe.
                    Online ist dies nicht möglich.`)}
                    meta={{scope: 'vn.', role: ''}}
                    touch={this.props.touch}
                />

                {this.state.showBeratungsDialog &&
                    <Modal
                        data-component-id="gwg-vermittler-suchen-modal"
                        open={this.state.showBeratungsDialog}
                        onDismiss={() => {
                            this.setState({showBeratungsDialog: false});
                            if (this.props.onGWGBeratungsdialogDismissed) {
                                this.props.onGWGBeratungsdialogDismissed();
                            }
                        }}
                        dismissible={true}
                        useEscKeyToDismiss={true}
                    >
                        <p>{this.state.beratungsDialogText}</p>
                        <p style={{textAlign: 'right'}}>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={() => {
                                    window.open('https://www.ergo.de/de/ResourcesUsability/VermittlersucheOne', '_blank');
                                    if (this.props.onGWGBeratungsdialogForward) {
                                        this.props.onGWGBeratungsdialogForward();
                                    }
                                }}
                                iconRight={ArrowRightIcon}
                            >
                                Vermittler finden
                            </Button>
                        </p>
                    </Modal>
                }
            </>
        );
    }

    private showBeratungsDialog = (beratungsDialogText: string) => {
        this.setState({showBeratungsDialog: true, beratungsDialogText});
    }
}
